var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-x-hidden",class:[
    _vm.item.active ? 'is-active' : null,
    _vm.useBorder ? 'accordion-item' : false ],attrs:{"id":_vm.groupId + '-' + _vm.item.id}},[_c('div',{staticClass:"accordion-item-title cursor-pointer"},[_c('div',{staticClass:"flex w-full flex-row text-left items-center bg-transparent border-transparent accordion-item-trigger pb-1",class:[
        parseInt(_vm.item.id) > 0 ? 'pb-1' : null,
        parseInt(_vm.item.id) === 0 && !_vm.item.active ? 'animate-pulse' : null ],on:{"click":_vm.toggle}},[_c('div',{staticClass:"flex flex-grow",on:{"mouseover":function($event){_vm.active = true},"mouseleave":function($event){_vm.active = false},"click":function($event){_vm.active = !_vm.active}}},[_c('div',{staticClass:"flex w-2 mr-1",style:(("background-color:" + (_vm.item.color)))}),_c('div',{staticClass:"flex justify-between text-xs px-2 py-1 rounded-r-md overflow-hidden transition-all duration-700",class:[
            !_vm.useBorder ? 'shadow-sm hover:shadow-lg' : null,
            _vm.monotone ? 'text-white bg-gray-400' : null,
            _vm.active ? 'bg-gray-500 text-gray-200' : null ],style:(_vm.item.active
              ? ("width: " + _vm.animatedBarSize + "%; background-color:" + (_vm.item.color) + ";")
              : ("width: " + _vm.animatedBarSize + "%;"))},[_c('span',{staticClass:"flex accordion-item-title-text font-semibold truncate overflow-clip mr-4"},[_vm._v(_vm._s(_vm.item.title))]),_c('span',{staticClass:"text-white text-left overflow-x-hidden truncate"},[_vm._v(_vm._s(_vm.item.total_interactions_label))])])])])]),_c('transition',{attrs:{"name":"accordion-item"},on:{"enter":_vm.startTransition,"after-enter":_vm.endTransition,"before-leave":_vm.startTransition,"after-leave":_vm.endTransition}},[(_vm.item.active)?_c('div',{staticClass:"flex flex-row w-full text-left overflow-hidden bg-transparent accordion-item-details mb-1"},[_c('div',{staticClass:"flex flex-grow flex-col bg-white py-3 pl-3 pr-3"},[(_vm.item.message != 0)?_c('p',{staticClass:"text-xs leading-relaxed mb-4 whitespace-pre-line"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Innlegg fra "+_vm._s(_vm.item.page_name)+" "+_vm._s(_vm.item.posted)+":")]),_c('br'),_vm._v(" "+_vm._s(_vm.item.message)+" ")]):_vm._e(),_c('span',{staticClass:"text-right"},[_vm._v("Se innlegget på "),_c('a',{staticClass:"text-atblue hover:underline",attrs:{"href":'https://www.facebook.com/' + _vm.item.url,"target":"_blank"}},[_vm._v("Facebook her")]),_vm._v(".")])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }