<template>
  <div class="flex flex-col w-full items-center content-center justify-center">
    <div
      id="intro"
      class="flex flex-col w-full min-h-screen bg-gradient-to-b from-atskin items-center content-center justify-center py-6 md:py-20"
    >
      <div class="flex w-full items-center content-center justify-center px-6">
        <span class="large-header">Vi følger valgkampen på sosiale medier</span>
      </div>
      <div
        class="flex flex-col md:flex-row w-full max-w-screen-lg items-center content-center md:justify-between"
      >
        <div
          class="flex flex-col px-6 pt-10 md:pt-20 md:mr-6 lg:ml-12 text-left"
        >
          <template v-for="(item, index) in introText">
            <p
              :key="index"
              class="intro-text"
              :class="[index === introText.length - 1 ? 'mb-8 md:mb-10' : null]"
            >
              <span v-html="item"></span>
            </p>
          </template>
        </div>
        <div
          class="flex flex-col w-full px-6 items-center content-center justify-center md:mr-6 lg:mr-12 md:place-self-end"
        >
          <span class="text-gray-600 font-bold md:text-base mb-6 md:mb-20"
            >Våre innsikter fra

            <span
              class="bg-atblue py-1 px-3 text-white rounded-full font-semibold"
              >Facebook</span
            >
            kommer fra disse feltene:
          </span>
          <FacebookMockup />
        </div>
      </div>
    </div>

    <div
      id="page"
      class="flex flex-col w-full justify-center items-center content-center mt-6 md:mt-32"
    >
      <div id="interactions" class="content-container">
        <span class="flex font-extrabold text-gray-600 uppercase text-xl mb-2"
          >Utforsk data</span
        >
        <div
          class="flex w-full md:w-1/2 md:rounded bg-gray-200 md:bg-gray-100 flex-col md:shadow-md md:hover:shadow-2xl md:mb-10 px-2 md:px-6 transform hover:-translate-y-0.5 transition-all duration-300"
        >
          <div
            class="flex flex-col justify-center items-center content-center pt-6"
          >
            <div class="flex flex-col sm:flex-row items-center mb-3 text-base">
              <div class="flex items-center">
                <span class="mr-1">Viser innsikter </span>
                <SetWeek
                  :preText="true"
                  :showDropDownButton="true"
                  :color="'normal'"
                />
              </div>
              <div class="flex items-center">
                fra følgende partier:
              </div>
            </div>

            <div
              class="grid grid-cols-3 md:grid-cols-9 mt-3 md:mt-0"
              v-if="!loadingStates.parties"
            >
              <template v-for="(item, index) in parties">
                <div
                  :key="index"
                  class="flex w-14 h-14 md:w-12 md:h-12 mb-3 mr-3 md:mb-0 md:mr-1"
                >
                  <PartyLogo
                    :party="item.party_abbr"
                    :circle="true"
                    :grayscale="false"
                    :clickable="false"
                    v-tooltip="{
                      content:
                        item.party_name +
                        ': ' +
                        item.total_party_pages +
                        ' Facebook sider',
                      placement: 'top',
                      trigger: 'hover click',
                    }"
                  />
                </div>
              </template>
            </div>
            <div
              class="flex flex-col justify-center items-center content-center my-3"
            >
              <div class="flex px-8 py-2">
                <span
                  @click="showSourcesDescription = !showSourcesDescription"
                  class="flex relative text-xs font-normal hover:font-bold cursor-pointer rounded bg-white border-2 border-atskin py-2 px-6 hover:border-4"
                >
                  Hvilke Facebook-sider er inkludert?
                  <span
                    v-if="!showSourcesDescription"
                    class="flex absolute h-3 w-3 -right-1.5 -top-1.5"
                  >
                    <span
                      class="animate-ping absolute inline-flex h-full w-full rounded-full bg-atskin opacity-75"
                    ></span>
                    <span
                      class="relative inline-flex rounded-full h-3 w-3 bg-atskin"
                    ></span>
                  </span>
                </span>
              </div>
              <transition name="expand">
                <div
                  v-if="showSourcesDescription"
                  class="overflow-hidden pt-3 px-3"
                >
                  <ul
                    class="text-sm list-disc list-inside ml-5 mb-2 leading-relaxed"
                  >
                    <li>Nasjonale partisider</li>
                    <li>Partienes fylkeslag</li>
                    <li>Partiledere, nestledere og partisekretærer</li>
                    <li>Stortingspolitikere</li>
                    <li>Partienes 5 øverste Stortingskadidater fra hver valgkrets</li>
                    <li>Ungdomspartiene nasjonalt</li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div
          class="flex w-full flex-col md:flex-row justify-between items-center mt-10 xl:my-16 mb-5 px-4"
        >
          <Counter
            v-if="!loadingStates.weekSummary"
            :week_nr="currentWeek['week_nr']"
            :loadingStates="loadingStates"
            header="Innlegg fra partiene og politikernes Facebook-sider"
            :countNumber="parseInt(currentWeek['posts'])"
            :subNumber="compareStats('posts')"
            id="posts"
          />
          <Counter
            v-if="!loadingStates.weekSummary"
            :week_nr="currentWeek['week_nr']"
            :loadingStates="loadingStates"
            header="Kommentarer til Facebook-innleggene"
            :countNumber="parseInt(currentWeek['comments'])"
            :subNumber="compareStats('comments')"
            id="comments"
          />
          <Counter
            v-if="!loadingStates.weekSummary"
            :week_nr="currentWeek['week_nr']"
            :loadingStates="loadingStates"
            header="Likes til Facebook-innleggene"
            :countNumber="parseInt(currentWeek['likes'])"
            :subNumber="compareStats('likes')"
            id="likes"
          />
        </div>

        <Analysis
          v-if="!loadingStates.weekSummary"
          :week_nr="currentWeek['week_nr']"
          :loadingStates="loadingStates"
        />
      </div>

      <div
        id="reactions"
        class="flex flex-col w-full items-center px-3 pb-6 md:pb-20 md:px-6 lg:mt-20"
        style="background-color: #fafafb;"
      >
        <div class="flex w-full max-w-screen-lg mt-10 md:mt-20">
          <span
            class="flex flex-col font-extrabold text-darkblack text-2xl md:text-4xl md:leading-snug mb-6 md:mb-12"
            >Observert engasjement på Facebook</span
          >
        </div>
        <div class="content-container">
          <div class="flex w-full flex-col md:flex-row justify-between">
            <div
              class="flex flex-col mr-3 md:mr-6"
              v-if="!loadingStates.weekSummary && maxParty !== null"
            >
              <p class="intro-text">
                I
                <SetWeek
                  :inline="true"
                  :color="'normal'"
                  class="ml-1 -mt-2 mb-1 md:mb-0 md:ml-0 md:-mt-1 inline-flex md:relative"
                />
                var
                <b v-if="!loadingStates.relInteractions">{{
                  remapPartyNames[maxParty["party_abbr"]]
                }}</b>
                det
                <span
                  class="md:px-3 md:py-1 md:border md:bg-white md:border-atskin md:rounded-full md:cursor-pointer md:hover:bg-atskin"
                  @click="toggleAverageEngagementDataType()"
                  :class="[
                    selectedDataTypes.averageEngagement ===
                    selectedDataTypesValues.averageEngagement[0]
                      ? 'md:bg-atskin'
                      : null,
                  ]"
                  >partiet</span
                >
                som skapte mest engasjement på Facebook.
              </p>
              <p class="intro-text">
                Med engasjement mener vi gjennomsnittet av likes,
                emoji-reaksjoner, kommentarer og delinger som et parti har
                mottatt i løpet av en uke. Med
                <span v-if="!loadingStates.relInteractions">{{
                  maxParty["posts"]
                }}</span>
                innlegg som genererte
                <span v-if="!loadingStates.relInteractions">
                  {{
                    parseInt(
                      maxParty["total_interactions_sum"]
                    ).toLocaleString()
                  }}</span
                >
                interaksjoner, hadde
                <span v-if="!loadingStates.relInteractions">{{
                  remapPartyNames[maxParty["party_abbr"]]
                }}</span>
                <span v-if="!loadingStates.relInteractions">
                  {{
                    parseInt(
                      maxParty["total_interactions_avg"]
                    ).toLocaleString()
                  }}</span
                >
                interaksjoner i snitt.
              </p>
              <p class="intro-text">
                <span v-if="!loadingStates.relInteractions">{{
                  remapPartyNames[minParty["party_abbr"]]
                }}</span>
                var det partiet som skapte minst engasjement, med
                <span v-if="!loadingStates.relInteractions">
                  {{
                    parseInt(
                      minParty["total_interactions_avg"]
                    ).toLocaleString()
                  }}</span
                >
                interaksjoner i snitt til sine
                <span v-if="!loadingStates.relInteractions">{{
                  minParty["posts"]
                }}</span>
                innlegg.
              </p>
              <p class="intro-text">
                Blant
                <span
                  class="md:px-3 md:py-1 md:border md:bg-white md:border-atskin md:rounded-full md:cursor-pointer md:hover:bg-atskin"
                  @click="toggleAverageEngagementDataType()"
                  :class="[
                    selectedDataTypes.averageEngagement !==
                    selectedDataTypesValues.averageEngagement[0]
                      ? 'md:bg-atskin'
                      : null,
                  ]"
                  >partilederne</span
                >
                var det
                <span class="font-bold" v-if="!loadingStates.relInteractions"
                  >{{ maxPartyLeader["page_name"] }} ({{
                    maxPartyLeader["party_abbr"]
                  }})</span
                >
                som skapte mest engasjement blant publikum på Facebook.
                <span v-if="!loadingStates.relInteractions"
                  >{{ minPartyLeader["page_name"] }} ({{
                    minPartyLeader["party_abbr"]
                  }})</span
                >
                var den partilederen som skapte minst engasjement i uken som
                gikk.
              </p>
            </div>

            <div class="flex flex-shrink-0 flex-col md:w-3/6">
              <WeekAverageEngagement
                v-if="!loadingStates.weekSummary"
                :logo="logoBlack"
                class="bg-white p-3 mb-10 shadow-md hover:shadow-xl rounded-md md:transform md:transition-all md:hover:scale-105 md:hover:-translate-y-1 md:duration-500"
                :class="[
                  fsWeekAverage
                    ? 'absolute left-0 -mt-3 mx-3 md:mx-32 z-20'
                    : 'relative',
                ]"
              />
            </div>
          </div>

          <div class="flex w-full flex-col md:flex-row justify-between">
            <div
              class="flex flex-col mr-3 md:mr-6"
              v-if="!loadingStates.weekSummary && maxTopPostParty !== null"
            >
              <p class="intro-text">
                I
                <SetWeek
                  :inline="true"
                  :color="'normal'"
                  class="ml-1 -mt-2 mb-1 md:mb-0 md:ml-0 md:-mt-1 inline-flex md:relative"
                />
                ble det skrevet
                {{ parseInt(currentWeek["posts"]).toLocaleString() }} innlegg av
                {{ parseInt(currentWeek["pages"]).toLocaleString() }} sider.
              </p>

              <p class="intro-text">
                På Facebook har
                <span
                  class="md:px-3 md:py-1 md:border md:bg-white md:border-atskin md:rounded-full md:cursor-pointer md:hover:bg-atskin"
                  @click="toggleTopEngagementDataType()"
                  :class="[
                    selectedDataTypes.topEngagement ===
                    selectedDataTypesValues.topEngagement[0]
                      ? 'md:bg-atskin'
                      : null,
                  ]"
                  >partiene</span
                >
                egne sider, både på nasjonalt nivå og i fylkene. Det
                Facebook-innlegget som fikk flest interaksjoner ble publisert på
                Facebook-siden
                <span class="font-bold" v-if="!loadingStates.topInteractions">{{
                  maxTopPostParty["page_name"]
                }}</span>
                og mottok
                <span v-if="!loadingStates.topInteractions">{{
                  parseInt(maxTopPostParty["like_count"]).toLocaleString()
                }}</span>
                likes,
                <span v-if="!loadingStates.topInteractions">{{
                  parseInt(
                    maxTopPostParty["total_likes"] -
                      maxTopPostParty["like_count"]
                  ).toLocaleString()
                }}</span>
                emoji reaksjoner,
                <span v-if="!loadingStates.topInteractions">{{
                  parseInt(maxTopPostParty["comments"]).toLocaleString()
                }}</span>
                kommentarer og
                <span v-if="!loadingStates.topInteractions">{{
                  parseInt(maxTopPostParty["share_count"]).toLocaleString()
                }}</span>
                delinger.
              </p>

              <p class="intro-text">
                Om vi også inkluderer sidene til
                <span
                  class="md:px-3 md:py-1 md:border md:bg-white md:border-atskin md:rounded-full md:cursor-pointer md:hover:bg-atskin"
                  @click="toggleTopEngagementDataType()"
                  :class="[
                    selectedDataTypes.topEngagement !==
                    selectedDataTypesValues.topEngagement[0]
                      ? 'md:bg-atskin'
                      : null,
                  ]"
                  >politikerne</span
                >
                , var det
                <span class="font-bold" v-if="!loadingStates.topInteractions">{{
                  maxTopPostPerson["page_name"]
                }}</span>
                som mottok flest interaksjoner i uke
                <span v-if="!loadingStates.weekSummary">{{
                  currentWeek["week_nr"]
                }}</span
                >.
              </p>

              <p class="intro-text">
                Ved å trykke på innleggene i grafen, kan du se hva de handlet
                om.
              </p>
            </div>
            <div class="flex flex-shrink-0 flex-col md:w-3/6">
              <WeekTopEngagement
                v-if="!loadingStates.weekSummary"
                :week_nr="currentWeek['week_nr']"
                :logo="logoBlack"
                :loadingStates="loadingStates"
                class="bg-white p-3 shadow-md hover:shadow-xl rounded-md md:transform md:transition-all md:hover:scale-105 md:hover:-translate-y-1 md:duration-500"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="content"
        class="flex flex-col w-full items-center px-3 pb-6 md:pb-20 md:px-6 lg:mt-10"
      >
        <div class="flex w-full max-w-screen-lg mt-10 md:mt-20">
          <span
            class="flex flex-col font-extrabold text-darkblack text-2xl md:text-4xl md:leading-snug mb-6 md:mb-12"
            >Publikums reaksjoner</span
          >
        </div>
        <div class="content-container">
          <div class="flex w-full flex-col md:flex-row justify-between">
            <div
              class="flex flex-col mr-3 md:mr-6"
              v-if="!loadingStates.weekSummary && maxAngryShare !== null"
            >
              <p class="intro-text">
                Utover hva partiene og politikerne selv kommuniserer, er det vel
                så interessant å se på hvordan budskapene deres blir mottatt
                gjennom
                <span
                  class="md:px-3 md:py-1 md:border md:bg-white md:border-atskin md:rounded-full md:cursor-pointer md:hover:bg-atskin"
                  @click="toggleEmojiReactionsDataType()"
                  :class="[
                    selectedDataTypes.emojiReactions ===
                    selectedDataTypesValues.emojiReactions[0]
                      ? 'md:bg-atskin'
                      : null,
                  ]"
                  >reaksjoner</span
                >
                til innleggene deres.
              </p>

              <p class="intro-text">
                Emojier forteller oss noe om den stemningen som blir skapt blant
                de som mottar de politiske budskapene. Men, alene forteller det
                oss ingenting om årsaken til at publikum velger å reagere på
                denne måten. Ser vi på budskap, tematikk og reaksjoner over tid
                kan det gi en større innsikt.
              </p>

              <p class="intro-text">
                I uke
                <SetWeek
                  :inline="true"
                  :color="'normal'"
                  class="ml-1 -mt-2 mb-1 md:mb-0 md:ml-0 md:-mt-1 inline-flex md:relative"
                />
                hadde
                <span v-if="!loadingStates.relInteractions" class="font-bold">{{
                  remapPartyNames[maxAngryShare["party_abbr"]]
                }}</span>
                størst andel av 😠 reaksjoner til sine Facebook-innlegg.
                <span v-if="!loadingStates.relInteractions" class="font-bold">{{
                  remapPartyNames[maxLoveShare["party_abbr"]]
                }}</span>
                hadde størst andel av ❤️ reaksjoner.
              </p>

              <p class="intro-text">
                Men det er også spennende å se på innholdet som skrives i
                kommentarfeltet til hvert parti. Her kan vi f.eks. hente ut de
                mest brukte
                <span
                  class="md:px-3 md:py-1 md:border md:bg-white md:border-atskin md:rounded-full md:cursor-pointer md:hover:bg-atskin"
                  @click="toggleEmojiReactionsDataType()"
                  :class="[
                    selectedDataTypes.emojiReactions !==
                    selectedDataTypesValues.emojiReactions[0]
                      ? 'md:bg-atskin'
                      : null,
                  ]"
                  >emojiene</span
                >
                i partiene sine kommentarfelt.
              </p>
            </div>
            <div class="flex flex-shrink-0 flex-col md:w-3/6">
              <!-- style="background-color: #f6f5f7;" -->
              <WeekEmojiReactions
                v-if="!loadingStates.weekSummary"
                :week_nr="currentWeek['week_nr']"
                :logo="logoBlack"
                :loadingStates="loadingStates"
                class="bg-white p-3 mb-10 shadow-md hover:shadow-xl rounded-md md:transform md:transition-all md:hover:scale-105 md:hover:-translate-y-1 md:duration-500"
              />
            </div>
          </div>

          <!-- <div class="flex w-full flex-col md:flex-row justify-between">
            <div
              class="flex flex-col mr-3 md:mr-6"
              v-if="!loadingStates.weekSummary"
            ></div>
            <div class="flex flex-shrink-0 flex-col md:w-3/6">
              
            </div>
          </div> -->
        </div>
      </div>

      <div class="flex flex-col w-full">
        <Methods v-if="!loadingStates.weekSummary" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.large-header {
  @apply flex md:w-4/5 lg:w-2/3 xl:w-1/2 font-extrabold text-darkblack text-3xl md:text-5xl lg:text-6xl md:leading-snug;
}
.content-container {
  @apply flex flex-col container max-w-screen-lg justify-center items-center content-center;
}
.intro-text {
  @apply text-sm md:text-base md:leading-loose mb-3 md:mb-4;
}
.card-skewed-top {
  @apply bg-white h-32 w-full;
}

.card-skewed-top:after {
  @apply absolute bottom-0 bg-atbrown h-32;
  content: "";
  width: 120%;
  left: -10%;
  transform: translateY(50%) rotate(-3deg);
}
.card-skewed-bottom {
  @apply bg-atbrown h-32 w-full;
}

.card-skewed-bottom:after {
  @apply absolute bottom-0 bg-white h-32;
  content: "";
  width: 120%;
  left: -10%;
  transform: translateY(50%) rotate(-3deg);
}
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, rgba(255, 255, 255, 0)),
    color-stop(20%, rgba(255, 255, 255, 0.2)),
    color-stop(60%, rgba(255, 255, 255, 0.5)),
    to(rgba(255, 255, 255, 0))
  );
  background-image: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 3s infinite;
  animation: shimmer 3s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease;
  max-height: 400px;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>

<script>
// * Store
import { mapGetters, mapActions, mapMutations } from "vuex";

// * Components
import SetWeek from "@/components/SetWeek.vue";
import PartyLogo from "@/components/PartyLogo.vue";
import Methods from "@/views/Methods.vue";
import WeekTopEngagement from "@/components/WeekTopEngagement.vue";
import WeekAverageEngagement from "@/components/WeekAverageEngagement.vue";
import WeekEmojiReactions from "@/components/WeekEmojiReactions.vue";
import Analysis from "@/components/Analysis.vue";
import FacebookMockup from "@/components/FacebookMockup.vue";
import Counter from "@/components/Counter.vue";
// import Circles from "@/components/Circles.vue";

export default {
  name: "Intro",
  components: {
    PartyLogo,
    WeekTopEngagement,
    WeekAverageEngagement,
    WeekEmojiReactions,
    Methods,
    SetWeek,
    Analysis,
    FacebookMockup,
    Counter,
  },
  data() {
    return {
      logo: require("@/assets/Logo-ikon.hvid.png"),
      // logoBlack: require("@/assets/Logo-ikon.sort.png"),
      logoBlack: require("@/assets/LogoNO-full.sorthvid-1.png"),
      parties: null,
      weeks: null,
      showSourcesDescription: false,
      introText: [
        "Store deler av valgkampen skjer gjennom sosiale medier. Over 80% av nordmenn som kan stemme ved årets valg har en profil på Facebook, og plattformen er den mest brukte blant norske politikere.",
        "Hver uke gir vi deg ferske tall på hvilke saker som opptar politikere, hvilke følelser det vekker hos velgere og hvordan partiene har klart å engasjere velgere. I <span class='font-semibold'>ukens aktuelt</span> kan du lese om aktuelle saker fra uken som har gått. Du kan også se utviklingen over tid ved å velge andre uker. Siden blir oppdatert med nye tall hver tirsdag.",
        "I <span class='font-bold'>Analyse & Tall</span> har vi lang erfaring med å levere skreddersydde og aktuelle analyser til media, politikere, myndigheter og organisasjoner. Denne siden viser kun et utdrag av de data og analyser vi kan tilby. Lurer du på hvem i ditt valgdistrikt som engasjerer mest? Hvordan en politiker har bygget seg opp over tid? Eller hvilket saksfelt partiene snakker mest om? ",
        "Ta kontakt med oss så hjelper vi deg.",
      ],
      fsWeekAverage: false,
      fsWeekTop: false,
      weeklyRelData: null,
      minParty: null,
      maxParty: null,
      minPartyLeader: null,
      maxPartyLeader: null,
      maxTopPostParty: null,
      maxTopPostPerson: null,
      maxAngryShare: null,
      maxLoveShare: null,
      // selectedRelToggleVal: "Alle partisider",
      remapPartyNames: {
        R: "Rødt",
        SV: "Sosialistisk Venstreparti",
        A: "Arbeiderpartiet",
        SP: "Senterpartiet",
        MDG: "Miljøpartiet De Grønne",
        KrF: "Kristelig Folkeparti",
        V: "Venstre",
        H: "Høyre",
        FrP: "Fremskrittspartiet",
        Sentrum: "Sentrum",
      },
    };
  },
  computed: {
    ...mapGetters("data", {
      loadingStates: "getLoading",
      allWeeksData: "getAllWeeksData",
      selectedWeek: "getSelectedWeek",
      // allRelativeWeekData: "getAllRelativeWeekData",
      selectedWeekRelativeWeekData: "getSelectedWeekRelativeWeekData",
      selectedWeekTopWeekData: "getSelectedWeekTopWeekData",
      selectedDataTypes: "getSelectedDataTypes",
      selectedDataTypesValues: "getSelectedDataTypesValues",
    }),
    currentWeek: {
      get() {
        // Set data to the selected week:
        return this.allWeeksData.filter((obj) => {
          return obj.week_nr === parseInt(this.selectedWeek);
        })[0];
      },
    },
  },
  methods: {
    ...mapActions("data", {
      getParties: "getParties",
    }),
    ...mapMutations("data", {
      setWeek: "SET_WEEK",
      setSelectedDataType: "SET_SELECTED_DATA_TYPE",
    }),
    async loadParties() {
      await this.getParties().then((response) => {
        this.parties = response;
      });
    },
    updateWeek(week) {
      this.setWeek(week);
    },
    compareStats(type) {
      let change, percentage_change, res;
      if (this.currentWeek !== null) {
        change = this.currentWeek[type] - this.currentWeek["prev_" + type];
        percentage_change = (change / this.currentWeek["prev_" + type]) * 100;

        if (change < 0) {
          // Negative figure
          res =
            Math.round(percentage_change) + "% sammenlignet med forrige uke";
          change;
        } else {
          res =
            "+" +
            Math.round(percentage_change) +
            "% sammenlignet med forrige uke";
        }
      }
      return res;
    },
    min(arr, element) {
      return arr.reduce(function(res, obj) {
        return obj[element] < res[element] ? obj : res;
      });
    },
    max(arr, element) {
      return arr.reduce(function(res, obj) {
        return obj[element] > res[element] ? obj : res;
      });
    },
    updateSummaryRel() {
      this.minParty = this.min(
        this.selectedWeekRelativeWeekData.all_pages,
        "total_interactions_avg"
      );
      this.maxParty = this.max(
        this.selectedWeekRelativeWeekData.all_pages,
        "total_interactions_avg"
      );
      this.minPartyLeader = this.min(
        this.selectedWeekRelativeWeekData.only_party_leaders,
        "total_interactions_avg"
      );
      this.maxPartyLeader = this.max(
        this.selectedWeekRelativeWeekData.only_party_leaders,
        "total_interactions_avg"
      );
    },
    calculateReactionsPercentage(type, obj) {
      return (
        (type /
          (obj.love_sum +
            obj.haha_sum +
            obj.wow_sum +
            obj.sad_sum +
            obj.angry_sum)) *
        100
      );
    },
    updateEmojiReactionsRel() {
      let l = this.selectedWeekRelativeWeekData.all_pages.map((obj) => ({
        ...obj,
        angry_share: this.calculateReactionsPercentage(obj.angry_sum, obj),
        love_share: this.calculateReactionsPercentage(obj.love_sum, obj),
      }));

      this.maxAngryShare = this.max(l, "angry_share");
      this.maxLoveShare = this.max(l, "love_share");
    },
    updateSummaryTop() {
      this.maxTopPostParty = this.max(
        this.selectedWeekTopWeekData.only_org_posts,
        "total_interactions"
      );
      this.maxTopPostPerson = this.max(
        this.selectedWeekTopWeekData.all_posts,
        "total_interactions"
      );
    },
    toggleAverageEngagementDataType() {
      if (
        this.selectedDataTypes.averageEngagement ===
        this.selectedDataTypesValues.averageEngagement[0]
      ) {
        this.setSelectedDataType({
          type: "averageEngagement",
          value: this.selectedDataTypesValues.averageEngagement[1],
        });
      } else {
        this.setSelectedDataType({
          type: "averageEngagement",
          value: this.selectedDataTypesValues.averageEngagement[0],
        });
      }
    },
    toggleTopEngagementDataType() {
      if (
        this.selectedDataTypes.topEngagement ===
        this.selectedDataTypesValues.topEngagement[0]
      ) {
        this.setSelectedDataType({
          type: "topEngagement",
          value: this.selectedDataTypesValues.topEngagement[1],
        });
      } else {
        this.setSelectedDataType({
          type: "topEngagement",
          value: this.selectedDataTypesValues.topEngagement[0],
        });
      }
    },
    toggleEmojiReactionsDataType() {
      if (
        this.selectedDataTypes.emojiReactions ===
        this.selectedDataTypesValues.emojiReactions[0]
      ) {
        this.setSelectedDataType({
          type: "emojiReactions",
          value: this.selectedDataTypesValues.emojiReactions[1],
        });
      } else {
        this.setSelectedDataType({
          type: "emojiReactions",
          value: this.selectedDataTypesValues.emojiReactions[0],
        });
      }
    },
  },
  async mounted() {
    this.loadParties();
  },
  watch: {
    selectedWeekRelativeWeekData() {
      this.updateSummaryRel();
      this.updateEmojiReactionsRel();
    },
    selectedWeekTopWeekData() {
      this.updateSummaryTop();
    },
  },
};
</script>
