import "intersection-observer/intersection-observer";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import VueObserveVisibility from "vue-observe-visibility";

Vue.use(VueObserveVisibility);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

// * TailwindCSS
import "@/assets/styles/index.css";

// * V-Tooltip
import VTooltip from "v-tooltip";

Vue.use(VTooltip, {
  defaultHtml: false,
});

// * Fonts
require("typeface-roboto");
require("typeface-dm-sans");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
