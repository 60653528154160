<template>
  <div v-if="loaded" class="flex flex-col p-6 bg-atbrown bg-opacity-30 w-full">
    <div
      class="flex flex-col relative w-full flex-row content-center justify-center pb-1 md:pb-4"
    >
      <div class="flex flex-col justify-center items-center text-center">
        <div
          class="md:flex relative font-bold text-base md:text-2xl px-6 pb-3 md:p-0 items-center"
        >
          <span
            class="relative mb-10 leading-loose font-bold text-base md:text-2xl"
          >
            Aktuelt uke {{ week_nr }}: {{ data.header }}
          </span>
        </div>
      </div>
      <p class="text-chunk" v-html="data.summary"></p>

      <span
        class="mt-3 text-center cursor-pointer text-sm font-bold hover:underline"
        v-show="!showContent"
        @click="showContent = !showContent"
      >
        Les hele analysen
      </span>

      <transition
        name="dropdown"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div v-if="showContent" class="overflow-hidden">
          <template v-for="(item, index) in data.content">
            <span :key="index" v-html="item"></span>
          </template>
        </div>
      </transition>
      <span
        class="mt-3 text-center cursor-pointer text-sm font-bold hover:underline"
        v-show="showContent"
        @click="showContent = !showContent"
      >
        Skjul analysen
      </span>
    </div>
  </div>
</template>

<style lang="postcss">
a {
  @apply text-darkblack font-bold;
}
a:hover {
  @apply underline;
}
.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.3s ease-in;
}

.dropdown-enter,
.dropdown-leave-to {
  height: 0 !important;
}

.text-chunk {
  @apply text-sm md:text-base mb-5 leading-relaxed;
}
.text-header {
  @apply text-base md:text-lg font-bold text-darkblack;
}
ul.lists {
  @apply list-disc list-inside text-sm md:text-base mb-5 leading-relaxed;
}
</style>

<script>
// * External
import axios from "axios";

export default {
  name: "Analysis",
  components: {},
  props: {
    week_nr: {},
    loadingStates: {
      type: Object,
    },
  },
  data() {
    return {
      loaded: false,
      showContent: false,
      data: null,
      relevant_weeks: [15, 16, 17, 18],
    };
  },
  methods: {
    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },
    endTransition(el) {
      el.style.height = "";
    },
    loadData() {
      let self = this;
      let week = "/static/analysis/week_" + this.week_nr + ".json";
      axios
        .get(week)
        .then((response) => {
          self.data = response.data;
          self.loaded = true;
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            // console.log(error.response.data);
            console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            // console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            // console.log("Error", error.message);
          }
          // console.log(error.config);
          self.loaded = false;
        });
    },
  },
  async mounted() {
    if (this.relevant_weeks.includes(this.week_nr)) {
      this.loadData();
    } else {
      this.loaded = false;
    }
  },
  watch: {
    week_nr() {
      if (this.relevant_weeks.includes(this.week_nr)) {
        this.loadData();
      } else {
        this.loaded = false;
      }
    },
  },
};
</script>
