<template>
  <div id="emojicloud" class="w-full h-full bg-transparent"></div>
</template>

<script>
import WordCloud from "wordcloud";

export default {
  name: "Circles",
  components: {},
  props: {
    partyEmojis: {},
    totalEmojis: {},
  },
  data() {
    return {
      loaded: false,
      wc: null,
    };
  },
  methods: {
    redraw() {
      WordCloud(document.querySelector("#emojicloud"), {
        list: this.partyEmojis,
        shape: "circle",
        drawOutOfBound: true,
        rotateRatio: 0,
        weightFactor: 4,
        // weightFactor: this.totalEmojis <= 800 ? 1.5 : 0.5,
        clearCanvas: true,
        backgroundColor: "#f3f4f6",
        classes: function() {
          // word, weight, fontSize, extraData
          return "top_emoji";
        },
        // hover: function(item, dimension, event) {
        //   console.log(item, dimension, event);
        // },
        click: function(item, dimension, event) {
          console.log(item, dimension, event);
        },
      });
    },
  },

  mounted() {
    this.redraw();
    // let el = document.querySelector("#emojicloud");
    // el.addEventListener("wordcloudstop", function() {
    //   // loop over all added elements (by class name)
    //   document.querySelectorAll(".top_emoji").forEach(function(element) {
    //     console.log(element);
    //     const text = element.innerHTML;
    //     element.innerHTML = `<a v-tooltip="{
    //       content: "Andel:",
    //       placement: "top",
    //       trigger: "hover click",
    //       autoHide: false,
    //       hideOnTargetClick: false,
    //     }" href="/tags/${text}" style="color: inherit;">${text}</a>`;
    //   });
    // });
  },
  watch: {
    partyEmojis() {
      this.redraw();
    },
  },
};
</script>

<style lang="postcss"></style>
