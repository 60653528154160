<template>
  <div class="flex flex-row">
    <a href="https://www.facebook.com/ogtal/" target="_blank">
      <Facebook
        width="25"
        height="25"
        class="social"
        alt="Facebook"
        title="Facebook"
      />
    </a>
    <a href="https://twitter.com/og_tal/" target="_blank">
      <Twitter
        width="25"
        height="25"
        class="social"
        alt="Twitter"
        title="Twitter"
      />
    </a>
    <a href="https://www.linkedin.com/company/ogtal/" target="_blank">
      <LinkedIn alt="LinkedIn" title="LinkedIn" height="25" width="25" />
    </a>
  </div>
</template>

<style lang="postcss">
.social {
  @apply mr-4;
}
</style>

<script>
import Facebook from "@/assets/social/facebook.svg";
import Twitter from "@/assets/social/twitter.svg";
import LinkedIn from "@/assets/social/linkedin.svg";

export default {
  name: "Footer",
  components: { Facebook, Twitter, LinkedIn },
  data() {
    return {};
  },
};
</script>
