var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full items-center"},[_c('div',{staticClass:"flex flex-col w-full bg-white items-center text-center"},[_c('div',{staticClass:"pb-1 text-md md:text-2xl font-bold items-center px-6 md:p-0"},[_vm._v(" Stemningen som ble skapt blant partienes publikum i "),_c('SetWeek',{staticClass:"ml-1 inline-flex md:relative",attrs:{"color":"normal"}})],1),_c('span',{staticClass:"flex text-xs md:text-base pb-2 md:pb-5 font-normal"},[_vm._v(_vm._s(_vm.selectedScopeSubTitle))]),_c('div',{staticClass:"flex flex-col w-full bg-gray-100 p-2"},[_c('div',{staticClass:"flex w-full relative justify-center"},[_c('ToggleSwitchMenu',{attrs:{"values":_vm.toggleValues,"options":_vm.selectedDataTypesValues.emojiReactions,"externalToggle":_vm.selectedDataTypes.emojiReactions,"id":"emojis"},on:{"selectedValue":_vm.setToggleValue}}),_c('div',{staticClass:"hidden lg:flex z-20 absolute top-0 right-0 mr-0 mt-1 md:mt-0"},[_c('img',{staticClass:"h-6 sm:h-8 md:h-10 w-auto",attrs:{"src":_vm.logo,"alt":"Laget av Analyse & Tall","title":"Laget av Analyse & Tall"}})])],1),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"relative flex flex-col h-80 bg-gray-100 mb-2"},[(_vm.loadingStates.emojis && _vm.loadingStates.relInteractions)?_c('div',{staticClass:"flex w-full h-full justify-center items-center content-center"},[_vm._v(" Laster... ")]):(
              !_vm.loadingStates.emojis && !_vm.loadingStates.relInteractions
            )?_c('div',{staticClass:"flex w-full h-full flex-col items-center justify-center content-center"},[_c('span',{staticClass:"flex font-semibold pt-3 md:text-xl"},[_vm._v(_vm._s(_vm.printPartyName))]),_c('div',{staticClass:"flex w-full h-full items-center justify-center content-center overflow-hidden"},[(
                  _vm.selectedParty !== null &&
                    _vm.selectedDataTypes.emojiReactions === 'comments'
                )?_c('div',{staticClass:"flex relative w-full h-full"},[_c('Circles',{staticClass:"-mt-5",attrs:{"partyEmojis":_vm.partyEmojisPercentage,"partyEmojisTotal":_vm.partyEmojisTotal,"totalEmojis":parseInt(_vm.selectedPartyEmojiInfo['num_emoji'])}})],1):(
                  _vm.selectedParty !== null &&
                    _vm.selectedDataTypes.emojiReactions === 'posts'
                )?_c('p',{staticClass:"flex flex-row items-center",style:(_vm.emojiSize)},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                      'Andel: ' + Math.round(_vm.emojiTypeSize('love_sum')) + '%',
                    placement: 'top',
                    trigger: 'hover click',
                  }),expression:"{\n                    content:\n                      'Andel: ' + Math.round(emojiTypeSize('love_sum')) + '%',\n                    placement: 'top',\n                    trigger: 'hover click',\n                  }"}],staticClass:"flex transform-gpu transition-all duration-500",style:(_vm.dataStyle('love_sum'))},[_vm._v("❤️")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                      'Andel: ' + Math.round(_vm.emojiTypeSize('haha_sum')) + '%',
                    placement: 'top',
                    trigger: 'hover click',
                  }),expression:"{\n                    content:\n                      'Andel: ' + Math.round(emojiTypeSize('haha_sum')) + '%',\n                    placement: 'top',\n                    trigger: 'hover click',\n                  }"}],staticClass:"flex transform-gpu transition-all duration-500",style:(_vm.dataStyle('haha_sum'))},[_vm._v("😂")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                      'Andel: ' + Math.round(_vm.emojiTypeSize('wow_sum')) + '%',
                    placement: 'top',
                    trigger: 'hover click',
                  }),expression:"{\n                    content:\n                      'Andel: ' + Math.round(emojiTypeSize('wow_sum')) + '%',\n                    placement: 'top',\n                    trigger: 'hover click',\n                  }"}],staticClass:"flex transform-gpu transition-all duration-500",style:(_vm.dataStyle('wow_sum'))},[_vm._v("😯")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                      'Andel: ' + Math.round(_vm.emojiTypeSize('sad_sum')) + '%',
                    placement: 'top',
                    trigger: 'hover click',
                  }),expression:"{\n                    content:\n                      'Andel: ' + Math.round(emojiTypeSize('sad_sum')) + '%',\n                    placement: 'top',\n                    trigger: 'hover click',\n                  }"}],staticClass:"flex transform-gpu transition-all duration-500",style:(_vm.dataStyle('sad_sum'))},[_vm._v("😢")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                      'Andel: ' +
                      Math.round(_vm.emojiTypeSize('angry_sum')) +
                      '%',
                    placement: 'top',
                    trigger: 'hover click',
                  }),expression:"{\n                    content:\n                      'Andel: ' +\n                      Math.round(emojiTypeSize('angry_sum')) +\n                      '%',\n                    placement: 'top',\n                    trigger: 'hover click',\n                  }"}],staticClass:"flex transform-gpu transition-all duration-500",style:(_vm.dataStyle('angry_sum'))},[_vm._v("😠")])]):_c('p',[_vm._v("Velg et parti under.")])])]):_vm._e(),(_vm.selectedDataTypes.emojiReactions === 'comments')?_c('div',{staticClass:"flex relative w-full text-xs font-normal pr-3 pb-3 text-center justify-center"},[(_vm.selectedParty !== null)?_c('span',{staticClass:"flex text-center"},[_vm._v(" "+_vm._s(parseInt( _vm.selectedPartyEmojiInfo["unique_emoji"] ).toLocaleString())+" unike emojier ble brukt "+_vm._s(parseInt(_vm.selectedPartyEmojiInfo["num_emoji"]).toLocaleString())+" ganger i "+_vm._s(parseInt(_vm.selectedPartyEmojiInfo["num_posts"]).toLocaleString())+" kommentarer. ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"flex justify-center flex-row px-6 mb-3 md:mx-6 lg:mx-16 flex-wrap"},[_vm._l((_vm.listParties),function(item,index){return [_c('div',{key:index,staticClass:"flex w-10 h-10 lg:w-12 lg:h-12 mr-1",on:{"click":function($event){return _vm.toggleParty(item)}}},[_c('PartyLogo',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.partyNames[item].name,
                  placement: 'top',
                }),expression:"{\n                  content: partyNames[item].name,\n                  placement: 'top',\n                }"}],attrs:{"party":item,"circle":true,"grayscale":true,"clickable":true,"active":_vm.selectedParty === item ? true : false}})],1)]})],2),_c('div',{staticClass:"flex lg:hidden w-full relative"},[_c('div',{staticClass:"flex absolute right-0 self-end mb-1 mr-1 sm:mb-2 sm:mr-1"},[_c('img',{staticClass:"h-6 sm:h-8 md:h-10 w-auto",attrs:{"src":_vm.logo,"alt":"Laget av Analyse & Tall","title":"Laget av Analyse & Tall"}})])])])]),_c('div',{staticClass:"flex w-full self-start p-2 sm:pl-0 sm:pt-1 sm:pb-0"},[_c('p',{staticClass:"font-light text-xs text-darkblack pt-1 pr-1 break-normal text-left"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Datagrunnlag:")]),_vm._v(" "+_vm._s(_vm.selectedScopeDescription)+" Kilde: Analyse & Tall 2021. ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }