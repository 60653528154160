<template>
  <div v-if="options" :class="baseClasses">
    <label v-if="$slots.default" :for="id" :class="labelClasses">
      <slot />
    </label>

    <div
      class="flex items-center content-center justify-center"
      :class="containerClasses"
    >
      <span v-if="preText" class="mr-1">
        for
      </span>
      <select
        :class="selectClasses"
        v-bind="{
          id,
          value,
          required,
          disabled,
          multiple: disableMultiple,
          ...$attrs,
        }"
        @change="toggleSelect"
        @click="hasBeenClicked = true"
      >
        <option v-if="placeholder" value="" disabled selected>{{
          placeholder
        }}</option>

        <option
          v-for="(option, optionIndex) in parsedOptions"
          :key="`${id}-${optionIndex}-${option.value}`"
          :value="option.value"
          :disabled="option.disabled ? 'disabled' : null"
        >
          <span v-if="weekText">uke</span> {{ option.label }}
        </option>
      </select>

      <div v-if="showDropDownButton" class="pointer-events-auto">
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          ></path>
        </svg>
      </div>

      <!-- <OtIcon iconName="chevron_down" :class="iconClasses" aria-hidden /> -->
    </div>
    <span
      v-if="!hasBeenClicked"
      class="flex absolute h-3 w-3 -top-1"
      :class="preText ? 'right-3' : '-right-1'"
    >
      <span
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-atskin opacity-75"
      ></span>
      <span class="relative inline-flex rounded-full h-3 w-3 bg-atskin"></span>
    </span>

    <transition name="ot-transitions-fade">
      <p v-if="error && errorMessage !== ''" class="ot-select__error-message">
        {{ errorMessage }}
      </p>
    </transition>
  </div>
</template>

<script>
// import OtIcon from "../OtIcon"

export default {
  name: "OtSelect",
  // components: { OtIcon },
  model: {
    prop: "model",
    event: "change",
  },
  props: {
    model: { type: [String, Number, Boolean, Object], default: null },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: [Array],
      required: true,
    },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    multiple: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    blend: { type: Boolean, default: false },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    preText: {
      type: Boolean,
      default: false,
    },
    showDropDownButton: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    weekText: {
      type: Boolean,
      default: true,
    },
    clicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasBeenClicked: false,
    };
  },
  computed: {
    value() {
      return this.model;
    },
    disableMultiple() {
      return false;
    },
    parsedOptions() {
      let opts = [];
      this.options.forEach((item) => {
        if (typeof item === "object") {
          opts.push({
            value: item.value,
            label: item.label,
            disabled: item.disabled || false,
          });
        } else if (typeof item !== "undefined") {
          opts.push({
            value: item,
            label: item,
          });
        }
      });
      return opts;
    },
    baseClasses() {
      const baseClass = "ot-select";
      return `${baseClass}`;
    },
    labelClasses() {
      const baseClass = "ot-select__label";
      const requiredClass = this.required ? ` ${baseClass}--required` : "";
      const disabledClass = this.disabled ? ` ${baseClass}--disabled` : "";

      return `${baseClass}${requiredClass}${disabledClass}`;
    },
    containerClasses() {
      const baseClass = "ot-select__container";
      const inlineClass = this.inline ? ` ${baseClass}--inline` : "";

      return `${baseClass}${inlineClass}`;
    },
    selectClasses() {
      const baseClass = "ot-select__select";

      const disabledClass = this.disabled ? ` ${baseClass}--disabled` : "";
      const errorClass = this.error ? ` ${baseClass}--error` : "";
      const blendClass = this.blend ? ` ${baseClass}--blend` : "";

      const normalColor =
        this.color === "normal" ? ` ${baseClass}--normalColor` : "";
      const grayColor = this.color === "gray" ? ` ${baseClass}--grayColor` : "";

      return `${baseClass}${disabledClass}${errorClass}${blendClass}${normalColor}${grayColor}`;
    },
    iconClasses() {
      const baseClass = "ot-select__icon";

      return `${baseClass}`;
    },
  },
  methods: {
    toggleSelect($event) {
      if (!this.disabled) {
        this.$emit("change", $event.target.value);
      }
    },
  },
  mounted() {
    this.hasBeenClicked = this.clicked;
  },
  watch: {
    clicked() {
      this.hasBeenClicked = this.clicked;
    },
  },
};
</script>

<style>
/* Base styles */
.ot-select {
  @apply relative text-black;
}

/* .ot-select__label {
  @apply mb-1;
  font-size: 0.875em;
} */

.ot-select__container {
  @apply relative;
}

.ot-select__select {
  @apply w-full border-0 rounded-md text-black font-bold p-1 appearance-none cursor-pointer;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
}

.ot-select__icon {
  @apply absolute pointer-events-none;
  right: 0.5em;
}

.ot-select__icon svg {
  width: 1em;
  height: 1em;
}

.ot-select__select::placeholder {
  @apply opacity-100;
}

/* Inline styles */
.ot-select__container--inline {
  @apply inline-block relative;
  select {
    @apply block appearance-none;
  }
}

/* Focus Styles */
.ot-select__select:focus {
  @apply outline-none border-0;
}

/* Required Styles */
.ot-select__label--required::after {
  content: "*";
}

/* Disabled Styles */
.ot-select__label--disabled {
  @apply text-gray-800;
}

.ot-select__select:disabled {
  @apply text-gray-600 border-gray-500;
}

.ot-select__select:disabled::placeholder {
  @apply text-gray-400;
}

.ot-select__select:disabled + .ot-select__icon {
  @apply text-gray-800;
}

/* error styles */
/* .ot-select__select--error {
  @apply border-warning-600;
} */

.ot-select__error-message {
  @apply absolute px-3 py-2 shadow border border-gray-200 rounded-lg text-red-700 bg-white;
  font-size: 0.875em;
  top: calc(100% + 0.5em);
}

/* blend styles */
.ot-select__select--blend {
  @apply bg-transparent font-semibold border-0 pl-0 pr-2 py-1 truncate w-11/12;
}

.ot-select__select--normalColor {
  @apply bg-white border-2 border-atskin bg-white hover:bg-atskin focus:ring-2 focus:ring-black;
}
.ot-select__select--grayColor {
  @apply bg-gray-100;
}
</style>
