<template>
  <div class="flex justify-center px-2 py-20 md:p-0">
    <div class="post">
      <!-- POST HEADER -->
      <div class="flex justify-between items-center p-3">
        <!-- header left -->
        <div class="flex relative items-center">
          <div
            class="flex relative"
            @mouseenter="hoveringAuthor = true"
            @mouseleave="hoveringAuthor = false"
            @click="hoveringAuthor = !hoveringAuthor"
          >
            <div class="flex absolute z-40 -top-20 mt-1.5 -right-20 md:-mr-2">
              <!-- md:right-11 md:m-0 md:top-1 -->
              <div
                class="flex flex-col w-36 md:w-40 text-xs bg-atskin rounded p-3 transition-all duration-300 ease-in-out"
                :class="[hoveringAuthor ? 'shadow-xl' : 'shadow-md']"
              >
                <span class="flex text-black font-bold">
                  Facebook-side
                </span>
                <div
                  class="flex font-normal text-black transition-all duration-300 ease-in-out"
                >
                  <span :class="[hoveringAuthor ? 'pt-2' : 'hidden']"
                    >En side fra en politiker eller partiorganisasjonen.</span
                  >
                </div>
              </div>
              <!-- <div
                class="hidden md:block w-12 h-1 bg-atskin rounded-r shadow-md mt-4"
              ></div> -->
              <div
                class="absolute block -bottom-6 left-2 w-12 h-1 bg-atskin rounded-r shadow-md transform rotate-90"
                :class="[hoveringAuthor ? 'hidden' : '']"
              ></div>
            </div>
            <span class="post__author-pic"></span>
          </div>

          <div
            @mouseenter="hoveringAuthor = true"
            @mouseleave="hoveringAuthor = false"
            class="flex flex-col content-center"
          >
            <span class="flex mb-1.5">
              <span class="flex rounded-md bg-gray-500 w-32 h-3 mr-1"> </span>
              <!-- <i class="fb__icons author__verified -mt-1"></i> -->
            </span>

            <div class="flex relative items-center">
              <span class="flex rounded-md bg-gray-300 w-16 h-3"> </span>
              <!-- <span class="mx-0.5">&nbsp;·&nbsp;</span>
              <i class="post__privacy"></i> -->
            </div>
          </div>
        </div>
        <!-- Header right -->
        <div class="flex">
          <div class="post__options">
            <i class="fb__icons options__icon"></i>
          </div>
        </div>
      </div>

      <!-- POST CONTENT -->
      <div
        @mouseenter="hoveringContent = true"
        @mouseleave="hoveringContent = false"
        class="flex relative"
      >
        <!-- <p class="content__paragraph">
          Facebook's Oculus Quest 2 starts shipping today! It's another big step
          forward for VR. I've been using mine all summer and I'm looking
          forward to more of you experiencing this.
        </p> -->
        <div class="flex flex-col w-full px-3 pb-6 pt-3">
          <div class="flex flex-row mb-2">
            <span class="word w-4"></span>
            <span class="word w-8"></span>
            <span class="word w-12"></span>
            <span class="word w-2"></span>
            <span class="word w-4"></span>
            <span class="word w-8"></span>
            <span class="word w-12"></span>
            <span class="word w-10"></span>
          </div>

          <div class="flex flex-row mb-2">
            <span class="word w-3"></span>
            <span class="word w-6"></span>
            <span class="word w-4"></span>
            <span class="word w-12"></span>
            <span class="word w-2"></span>
            <span class="word w-8"></span>
            <span class="word w-4"></span>
            <span class="word w-14"></span>
          </div>

          <div class="flex flex-row">
            <span class="word w-12"></span>
            <span class="word w-3"></span>
            <span class="word w-10"></span>
            <span class="word w-6"></span>
            <span class="word w-6"></span>
          </div>
        </div>
        <div
          @mouseenter="hoveringContent = true"
          @mouseleave="hoveringContent = false"
          class="flex relative"
        >
          <div
            class="flex absolute -left-36 -top-32 md:-mt-1.5 lg:-left-12 lg:top-3 lg:m-0 items-start"
          >
            <div
              class="hidden lg:block w-16 h-1 mt-4 bg-atskin rounded-l shadow-md"
            ></div>
            <div
              class="absolute right-1 block lg:hidden w-28 h-1 bg-atskin rounded-l shadow-md transform -rotate-90"
              :class="[hoveringContent ? 'top-24 -mt-1' : '-bottom-14']"
            ></div>
            <div
              class="flex flex-col text-xs bg-atskin rounded p-3 w-36 lg:w-40 transition-all duration-300 ease-in-out"
              :class="[hoveringContent ? 'shadow-xl z-10' : 'shadow-md']"
            >
              <span class="flex text-black font-bold">
                Innlegg
              </span>
              <div class="flex font-normal text-black">
                <span :class="[hoveringContent ? 'pt-2' : 'hidden']"
                  >Budskapet fra de partipolitiske Facebook-sidene.</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- <img
          :src="require('@/assets/fb-post/post-pic.jpg')"
          class="content__image"
        /> -->
      </div>

      <div class="footer__reactions">
        <div class="flex relative">
          <div class="flex absolute z-10 -right-42 top-40 -ml-3 items-start">
            <!-- md:m-0 md:right-0 md:-top-5 -->
            <div
              class="flex z-20 flex-col text-xs bg-atskin rounded p-3 w-36 md:w-40 transition-all duration-300 ease-in-out"
              :class="[hoveringInteractions ? 'shadow-xl' : 'shadow-md']"
              @mouseenter="hoveringInteractions = true"
              @mouseleave="hoveringInteractions = false"
            >
              <span class="flex text-black font-bold">
                Totale interaksjoner
              </span>
              <div class="flex font-normal text-black">
                <span :class="[hoveringInteractions ? 'pt-2' : 'hidden']"
                  >Totale interaksjoner til et innlegg inkluderer likes,
                  emoji-reaksjoner, kommentarer og delinger.</span
                >
              </div>
            </div>
            <!-- <div class="hidden md:block w-8 h-1 bg-atskin mt-4 shadow-md"></div> -->
            <div
              class="absolute -top-16 -left-6 block w-40 h-1 bg-atskin transform -rotate-90 shadow-md"
            ></div>
          </div>
        </div>

        <div
          class="flex relative justify-between w-full transition-all duration-300 ease-in-out p-2 box-border border-atskin"
          :class="[
            hoveringInteractions
              ? 'border-4 rounded-md'
              : 'border-l-4 border-b-4',
          ]"
          @mouseenter="hoveringInteractions = true"
          @mouseleave="hoveringInteractions = false"
        >
          <div class="flex items-center justify-between relative">
            <Like class="relative z-20" height="20" width="20" />
            <Haha class="relative -ml-1.5 z-10" height="20" width="20" />
            <Love class="relative -ml-1.5" height="20" width="20" />

            <span class="emojis__count">
              193
            </span>
          </div>
          <div class="reactions__comment-shares">
            <span class="comment-shares__comments">50 kommentarer</span>
            <span class="comment-shares__shares">12 delinger</span>
          </div>
        </div>
      </div>

      <div class="flex footer__comments">
        <!-- Friend comment -->
        <div class="flex flex-row justify-between">
          <span class="friend-comment__pic"></span>
          <div class="flex flex-col">
            <div
              @mouseenter="hoveringComments = true"
              @mouseleave="hoveringComments = false"
              class="friend-comment__comment"
            >
              <span class="flex rounded-md bg-gray-400 w-32 h-2.5 mb-2"> </span>
              <div class="flex flex-col w-full">
                <div class="flex flex-row mb-2">
                  <span class="word w-4"></span>
                  <span class="word w-8"></span>
                  <span class="word w-12"></span>
                  <span class="word w-2"></span>
                  <span class="word w-10"></span>
                </div>

                <div class="flex flex-row mb-2">
                  <span class="word w-3"></span>
                  <span class="word w-6"></span>
                  <span class="word w-4"></span>
                  <span class="word w-12"></span>
                  <span class="word w-4"></span>
                  <span class="word w-8"></span>
                </div>

                <div class="flex flex-row">
                  <span class="word w-12"></span>
                  <span class="word w-2"></span>
                  <span class="word w-10"></span>
                  <span class="word w-4"></span>
                </div>
              </div>
              <!-- <span class="comment__content">
                It sure feels different to see you on a different color T-shirt,
                but still, technology advances everytime and we are glad that
                you're a part of it.
              </span> -->
              <!-- <div class="comment__reactions reactions">
              <Like class="relative z-20" height="24" width="24" />
              <Haha class="relative -ml-1.5 z-10" height="24" width="24" />
              <Love class="relative -ml-1.5" height="24" width="24" />
              <span class="reactions__count">70</span>
            </div> -->
              <!-- <div class="comment__replies">
              <i class="replies__emoji"></i>
              <span class="text-xs">Vis 17 svar til</span>
            </div> -->
            </div>
            <div
              @mouseenter="hoveringComments = true"
              @mouseleave="hoveringComments = false"
              class="flex relative self-center"
            >
              <div
                class="flex absolute z-40 left-0 -mt-0.5 top-12 md:left-20 md:ml-0 md:-top-16 items-start"
              >
                <div
                  class="hidden md:block w-20 h-1 mt-4 bg-atskin rounded-l shadow-md"
                ></div>
                <div
                  class="absolute -left-2 -top-8 block md:hidden w-16 h-1 bg-atskin rounded-l shadow-md transform rotate-90"
                ></div>
                <div
                  class="flex flex-col text-xs bg-atskin rounded p-3 w-36 md:w-40 transition-all duration-300 ease-in-out"
                  :class="[hoveringComments ? 'shadow-xl' : 'shadow-md']"
                >
                  <span class="flex text-black font-bold">
                    Kommentarer
                  </span>
                  <div class="flex font-normal text-black">
                    <span :class="[hoveringComments ? 'pt-2' : 'hidden']"
                      >Innholdet i kommentarene gir en pekepinn på hvordan
                      budskapet i innleggene blir mottatt.</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="comment__links">
              <span class="links__like">Liker</span>
              <span class="mx-0.5">&nbsp;·&nbsp;</span>
              <span class="links__reply">Svar</span>
              <span class="mx-0.5">&nbsp;·&nbsp;</span>
              <span class="links__date">16 uker</span>
            </div>
          </div>
          <!-- <div class="friend-comment__options options">
                <i class="options__icon options__comment"></i>
              </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Like from "@/assets/fb-post/like.svg";
import Haha from "@/assets/fb-post/haha.svg";
import Love from "@/assets/fb-post/love.svg";

export default {
  components: {
    Like,
    Haha,
    Love,
  },
  data() {
    return {
      hoveringInteractions: false,
      hoveringAuthor: false,
      hoveringContent: false,
      hoveringComments: false,
    };
  },
  mounted() {},
};
</script>

<style lang="postcss">
.word {
  @apply flex bg-gray-300 h-2 rounded mr-1.5;
}

:root {
  --white: #fff;
  --background: #f0f2f5;
  --hover-shadow: #f2f2f2;
  --hover-shadow-2: #e4e6e9;
  --border: #ccc;
  --box-shadow: rgba(0, 0, 0, 0.2);
  --reactions-links: #65676b;
  --black1: #1c1e21;
  --black2: #050505;
  --green: #2f9a48;
}

a {
  text-decoration: none;
  color: inherit;
}

.post {
  @apply flex flex-col max-w-7xl bg-white;
  /* max-width: 20rem; */
  border-radius: 8px;
  box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
  font-family: "Segoe UI", Helvetica, Arial, Helvetica, sans-serif;
}
.post__author-pic {
  @apply w-10 h-10 md:h-12 md:w-12 bg-gray-100;
  float: left;
  margin-right: 0.8rem;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
  transition: filter 0.2s ease;
}
.post__options {
  @apply p-3 rounded-full;
  line-height: 50%;
  transition: background-color 0.2s ease;
}
.post__options:hover {
  background: var(--hover-shadow);
}
.content__paragraph {
  /* font-size: 1.5rem; */
  @apply pt-0 px-3 pb-3;
  /* padding: 0rem 1.6rem 0.8rem 1.6rem; */
  font-size: 0.875rem;
  line-height: 1.3333;
}
.content__image {
  @apply h-96 w-full;
  object-fit: cover;
  display: block;
}

/* POST INTERACTIONS */
.footer__reactions {
  @apply flex text-xs md:text-sm relative px-3 justify-between;
  align-items: center;
  color: var(--reactions-links);
  /* font-size: 0.9375rem; */
  /* line-height: 1.3333; */
}
.emojis__count {
  @apply ml-0.5;
}

.comment-shares__shares {
  @apply ml-1;
}
.emojis__count:hover,
.comment-shares__comments:hover,
.comment-shares__shares:hover {
  text-decoration: underline;
}

/* POST COMMENTS */
.footer__comments {
  @apply p-3;
  font-weight: 600;
  text-align: left;
  color: var(--reactions-links);
}

.friend-comment__pic {
  @apply flex flex-shrink-0 w-10 h-10 mr-3 bg-gray-100;
  border-radius: 50%;
  transition: filter 0.2s ease;
}

/* .friend-comment__pic:hover {
  filter: brightness(0.95);
} */
.friend-comment__comment {
  @apply flex flex-col relative p-3;
  background: var(--background);
  border-radius: 18px;
}

.comment__author {
  @apply text-sm font-semibold;
  align-self: flex-start;
  color: var(--black2);
}

.comment__author:hover {
  text-decoration: underline;
}

.comment__content {
  /* font-size: 1.5rem; */
  font-size: 0.875rem;
  line-height: 1.3333;
  font-weight: 400;
  color: var(--black2);
}

.comment__reactions {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -1.5rem;
  right: 0;
  padding: 0.2rem 0.5rem;
  background: var(--white);
  box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
  border-radius: 1rem;
  cursor: pointer;
}
.comment__links {
  @apply flex relative text-xs mt-1 ml-2;
  color: var(--reactions-links);
  /* font-size: 1.2rem; */
  font-weight: 400;
}

.comment__replies {
  @apply flex absolute left-0 -bottom-10;
  align-items: center;
}

.comment__replies:hover {
  cursor: pointer;
  text-decoration: underline;
}

.links__like,
.links__reply {
  font-weight: 700;
}

.links__like:hover,
.links__reply:hover,
.links__date:hover {
  text-decoration: underline;
}

/* POST ICONS */
.fb__icons {
  @apply w-5 h-5;
  display: inline-block;
  background-image: url(~@/assets/fb-post/icons.png);
  background-repeat: no-repeat;
}
.author__verified {
  width: 12px;
  height: 12px;
  background-position: -17px -744px;
  background-size: 33px 948px;
  background-repeat: no-repeat;
  display: inline-block;
}
.post__privacy {
  background-image: url(~@/assets/fb-post/icons-2.png);
  background-position: -13px -286px;
  background-size: 73px 312px;
  display: inline-block;
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  opacity: 0.5;
}
.options__icon {
  position: relative;
  background-size: 33px 948px;
  background-repeat: no-repeat;
  display: inline-block;
  background-position: 0 -494px;
  height: 20px;
  width: 20px;
  opacity: 0.5;
}
.replies__emoji {
  background-image: url("~@/assets/fb-post/icons-3.png");
  background-size: 73px 346px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-position: -51px -260px;
  opacity: 0.6;
  margin-right: 0.5rem;
}
</style>
