<template>
  <div class="flex flex-col bg-atbrown w-full items-center py-10 md:py-16">
    <div
      class="flex flex-col max-w-screen-lg items-center justify-center px-4 lg:px-32"
    >
      <span class="text-2xl md:text-4xl font-bold text-center mb-10 text-white"
        >Om oss</span
      >

      <p class="text-sm md:text-base mb-5 leading-relaxed">
        I analysebyrået
        <a
          href="https://www.ogtall.no/"
          target="_blank"
          class="font-semibold text-white hover:underline"
          >Analyse &amp; Tall</a
        >
        er vi spesialisert i å telle det som er vanskelig. Vi analyserer
        komplekse størrelser som frivillighet, digitale fellesskap og spredning
        av feilinformasjon på sosiale medier. Vi finner mønstre og mening i
        store datasett og digitale virkeligheter som vi formidler gjennom
        grundige analyser og tall.
      </p>

      <p class="text-sm md:text-base mb-5 leading-relaxed">
        Vårt team består av 20 medarbeidere som arbeider sammen på tvers av våre
        kontorer i Oslo, København og Aarhus. Vi ønsker å skape et mer
        demokratisk og rettferdig samfunn. Derfor har vi valgt å organisere oss
        som et
        <span class="font-semibold">medarbeidereid kooperativ</span>, hvor alle
        medarbeidere eier og styrer virksomheten sammen.
      </p>

      <p class="text-sm md:text-base mb-5 leading-relaxed">
        Ønsker du en analyse om hvordan det står til med et parti,
        stortingsrepresentantene fra et fylke eller hvilken plass din
        valgkampsak har i media? Ta kontakt med oss, så tar vi gjerne en prat om
        hvordan vi kan hjelpe.
      </p>
    </div>

    <div class="flex">
      <div class="grid grid-cols-1 gap-0 md:gap-2 lg:grid-cols-2 lg:gap-4">
        <div class="md:p-6">
          <figure class="flex items-center">
            <div class="pr-5 md:p-8 text-right space-y-4">
              <figcaption>
                <div class="text-sm md:text-base font-semibold text-darkblack">
                  Håvard Lundberg
                </div>
                <div class="text-sm md:text-base font-light text-gray-700">
                  Partner, Analyse &amp; Tall SA
                </div>
                <div class="pt-4 text-xs md:text-sm">
                  <a
                    class="text-white hover:underline"
                    href="mailto:haavard@ogtall.no"
                    >haavard@ogtall.no</a
                  >
                </div>
                <div class="text-gray-700 text-xs md:text-sm">
                  +47 957 93 255
                </div>
              </figcaption>
            </div>
            <img
              class="w-32 h-32 md:w-48 md:h-48 grayscale-img rounded-full"
              src="@/assets/havard.jpg"
              alt=""
            />
          </figure>
        </div>

        <div class="pt-6 md:p-6">
          <figure class="flex items-center">
            <img
              class="w-32 h-32 md:w-48 md:h-48 grayscale-img rounded-full"
              src="@/assets/ingvild.jpg"
              alt=""
            />
            <div class="pl-5 md:p-8 text-left space-y-4">
              <figcaption>
                <div class="text-sm md:text-base font-semibold text-darkblack">
                  Ingvild Endestad
                </div>
                <div class="text-sm md:text-base font-light text-gray-700">
                  Partner, Analyse &amp; Tall SA
                </div>
                <div class="pt-4 text-xs md:text-sm">
                  <a
                    class="text-white hover:underline"
                    href="mailto:ingvild@ogtall.no"
                    >ingvild@ogtall.no</a
                  >
                </div>
                <div class="text-gray-700 text-xs md:text-sm">
                  +47 975 62 295
                </div>
              </figcaption>
            </div>
          </figure>
        </div>
      </div>
    </div>

    <!-- <div class="flex sm:hidden mt-12"><SocialSharing /></div> -->
  </div>
</template>

<style lang="postcss" scoped>
.grayscale-img {
  @apply transform transition duration-500 ease-in-out;
  filter: saturate(0%);
}
.grayscale-img:hover {
  @apply transform transition duration-500 ease-in-out;
  filter: saturate(35%) !important;
}
</style>

<script>
// * Store
import { mapGetters } from "vuex";

// // * Components
// import Methods from "@/views/Methods.vue";

export default {
  name: "Us",
  // components: { Methods },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("data", {
      loadingStates: "getLoading",
    }),
  },
};
</script>
