var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex relative w-full flex-row content-center justify-center pb-1 md:pb-4"},[_c('div',{staticClass:"flex flex-col justify-center items-center text-center"},[_c('div',{staticClass:"relative font-bold text-base md:text-xl items-center px-6 pb-3 md:p-0"},[_vm._v(" Engasjement skapt av "+_vm._s(_vm.selectedDataTypes.averageEngagement === "all_pages" ? "partiene" : "partilederne")+" på Facebook i "),_c('span',{staticClass:"relative"},[_c('SetWeek',{staticClass:"ml-1 mt-2 md:mt-0 inline-flex md:relative",attrs:{"inline":true,"color":'normal'}})],1)])])]),_c('div',{staticClass:"flex flex-col w-full bg-gray-100 text-left"},[_c('div',{staticClass:"flex relative w-full justify-center px-2 md:p-0 mb-3 md:mb-0 mt-3"},[_c('ToggleSwitchMenu',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              'Filtrer data basert på alle partisider, eller kun sidene til hver partileder.',
            placement: 'top',
            trigger: 'hover click',
          }),expression:"{\n            content:\n              'Filtrer data basert på alle partisider, eller kun sidene til hver partileder.',\n            placement: 'top',\n            trigger: 'hover click',\n          }"}],attrs:{"values":_vm.toggleValues,"options":_vm.selectedDataTypesValues.averageEngagement,"externalToggle":_vm.selectedDataTypes.averageEngagement,"id":"avg"},on:{"selectedValue":_vm.setToggleValue}}),_c('div',{staticClass:"flex absolute top-0 right-0 mr-2 mt-1 md:mt-0 md:mr-3"},[_c('img',{staticClass:"h-6 sm:h-8 md:h-10 w-auto",attrs:{"src":_vm.logo,"alt":"Laget av Analyse & Tall","title":"Laget av Analyse & Tall"}})])],1),_c('div',{staticClass:"relative flex flex-col w-full p-2 md:p-3"},[(_vm.loadingStates.relInteractions)?_c('div',{staticClass:"flex justify-center items-center content-center h-64"},[_vm._v(" Laster... ")]):_vm._e(),(!_vm.loadingStates.relInteractions)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
            callback: _vm.visibilityChanged,
            once: true,
          }),expression:"{\n            callback: visibilityChanged,\n            once: true,\n          }"}],staticClass:"flex flex-col w-full"},_vm._l((_vm.selectedData),function(item,id){return _c('bar',{key:id,attrs:{"item":item,"groupId":_vm.selectedDataTypes.averageEngagement,"id":id,"barSize":_vm.calculateBarSize(parseInt(item[_vm.barValueElement])),"partyNames":_vm.partyNames,"selectedDataType":_vm.selectedDataTypes.averageEngagement,"barValueElement":_vm.barValueElement,"isVisible":_vm.isVisible}})}),1):_vm._e()])])]),_c('div',{staticClass:"flex w-full pt-3"},[_c('p',{staticClass:"font-light text-xs text-darkblack text-left"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Datagrunnlag:")]),_vm._v(" "+_vm._s(_vm.selectedScopeDescription)+" Kilde: Analyse & Tall 2021. ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }