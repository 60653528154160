<template>
  <div
    :id="groupId + '-' + item.id"
    class="overflow-x-hidden"
    :class="[
      item.active ? 'is-active' : null,
      useBorder ? 'accordion-item' : false,
    ]"
  >
    <div class="accordion-item-title cursor-pointer">
      <div
        @click="toggle"
        class="flex w-full flex-row text-left items-center bg-transparent border-transparent accordion-item-trigger pb-1"
        :class="[
          parseInt(item.id) > 0 ? 'pb-1' : null,
          parseInt(item.id) === 0 && !item.active ? 'animate-pulse' : null,
        ]"
      >
        <div
          class="flex flex-grow"
          @mouseover="active = true"
          @mouseleave="active = false"
          @click="active = !active"
        >
          <div
            class="flex w-2 mr-1"
            :style="`background-color:${item.color}`"
          ></div>
          <div
            class="flex justify-between text-xs px-2 py-1 rounded-r-md overflow-hidden transition-all duration-700"
            :class="[
              !useBorder ? 'shadow-sm hover:shadow-lg' : null,
              monotone ? 'text-white bg-gray-400' : null,
              active ? 'bg-gray-500 text-gray-200' : null,
            ]"
            :style="
              item.active
                ? `width: ${animatedBarSize}%; background-color:${item.color};`
                : `width: ${animatedBarSize}%;`
            "
          >
            <span
              class="flex accordion-item-title-text font-semibold truncate overflow-clip mr-4"
              >{{ item.title }}</span
            >
            <span class="text-white text-left overflow-x-hidden truncate">{{
              item.total_interactions_label
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <transition
      name="accordion-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <div
        v-if="item.active"
        class="flex flex-row w-full text-left overflow-hidden bg-transparent accordion-item-details mb-1"
      >
        <div class="flex flex-grow flex-col bg-white py-3 pl-3 pr-3">
          <p
            v-if="item.message != 0"
            class="text-xs leading-relaxed mb-4 whitespace-pre-line"
          >
            <span class="font-semibold"
              >Innlegg fra {{ item.page_name }} {{ item.posted }}:</span
            ><br />
            {{ item.message }}
          </p>
          <span class="text-right"
            >Se innlegget på
            <a
              class="text-atblue hover:underline"
              :href="'https://www.facebook.com/' + item.url"
              target="_blank"
              >Facebook her</a
            >.</span
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: [
    "item",
    "barSize",
    "multiple",
    "groupId",
    "useBorder",
    "monotone",
    "isVisible",
  ],
  data: () => ({
    active: false,
  }),
  computed: {
    animatedBarSize() {
      if (this.isVisible) {
        return this.barSize;
      } else {
        return 0;
      }
    },
    barStyle() {
      if (!this.monotone) {
        return {
          "background-color": `${this.item.color}`,
          width: `${this.animatedBarSize}%`,
        };
      } else {
        return {
          width: `${this.animatedBarSize}%`,
        };
      }
    },
    chartStyle() {
      return {
        // "min-height": `${this.minHeight}px`,
        // "max-height": `${this.maxHeight}px`,
        position: "relative",
      };
    },
    itemMessage() {
      return this.item.message.replace(/(\\r)*\\n/g, "<br>");
    },
  },
  methods: {
    toggle(event) {
      if (this.multiple) this.item.active = !this.item.active;
      else {
        this.$parent.$children.forEach((item) => {
          if (
            item.$el.id === event.currentTarget.parentElement.parentElement.id
          )
            item.item.active = !item.item.active;
          else item.item.active = false;
        });
      }
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },
    endTransition(el) {
      el.style.height = "";
    },
  },
};
</script>

<style lang="postcss" scoped>
.accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(10, 10, 10, 0.1);
}
.accordion-item:last-child .accordion-item-details {
  @apply rounded-b-md mb-0;
}

.accordion-item-title {
  @apply relative flex text-xs;
}

/* .accordion-item-trigger-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-right: 2px solid #363636;
  border-bottom: 2px solid #363636;
  transform: translateY(-2px) rotate(45deg);
  transition: transform 0.2s ease;
}
.is-active .accordion-item-trigger-icon {
  transform: translateY(2px) rotate(225deg);
} */

.accordion-item-details {
  @apply text-xs overflow-hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease-in;
}

.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>
