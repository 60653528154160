<template>
  <div
    :id="'toggle-switch-' + id"
    class="flex z-20 relative flex-row text-xs rounded-full py-0.5 px-0.5 border border-gray-300 justify-center items-center content-center overflow-hidden"
  >
    <span
      class="flex z-10 absolute bg-atskin text-atskin py-1 rounded-full transform transition-all duration-700 dark:bg-atskin dark:text-atskin"
      :class="selectedClass"
      :style="computedWidth"
    ></span>
    <span
      class="block z-20 rounded-full py-1 px-3 mr-2 cursor-pointer truncate overflow-x-hidden"
      :class="[selectedValue === values[0] ? 'font-bold' : null]"
      @click="toggleActive = !toggleActive"
      ref="option_1"
      >{{ values[0] }}</span
    >
    <span
      class="flex z-20 rounded-full py-1 px-3 cursor-pointer truncate overflow-x-hidden"
      :class="[selectedValue === values[1] ? 'font-bold' : null]"
      @click="toggleActive = !toggleActive"
      ref="option_2"
      >{{ values[1] }}</span
    >
    <div
      class="absolute z-0 bg-white w-full h-full rounded-full py-0.5 px-0.5"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ToggleSwitchMenu",
  props: {
    options: {
      required: false,
      default: ["val_1", "val_2"],
    },
    values: {
      required: true,
      default: ["Visible value 1", "Visible value 2"],
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    externalToggle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      toggleActive: false,
      selectedValue: null,
      computedWidth: 0,
    };
  },
  computed: {
    // selectedValue() {},
    selectedClass() {
      let style;
      if (this.id === "avg") {
        if (this.selectedValue === this.values[0]) {
          style = "-translate-x-2/4 -ml-5";
        } else {
          style = "translate-x-2/4 ml-2";
        }
      } else if (this.id === "top") {
        if (this.selectedValue === this.values[1]) {
          style = "translate-x-2/4";
        } else {
          style = "-translate-x-2/4 -ml-6";
        }
      } else if (this.id === "emojis") {
        if (this.selectedValue === this.values[1]) {
          style = "translate-x-2/4 -ml-3";
        } else {
          style = "-translate-x-2/4 -ml-10";
        }
      }
      return style;
    },
  },
  methods: {
    generateStyle() {
      if (this.selectedValue === this.values[0]) {
        this.computedWidth = `width: ${this.$refs.option_1.clientWidth}px; height: ${this.$refs.option_1.clientHeight}px;`;
      } else {
        this.computedWidth = `width: ${this.$refs.option_2.clientWidth}px; height: ${this.$refs.option_2.clientHeight}px;`;
      }
    },
  },
  mounted() {
    this.selectedValue = this.values[0];
    this.generateStyle();
  },
  watch: {
    toggleActive() {
      this.selectedValue = !this.toggleActive ? this.values[0] : this.values[1];
      this.$emit("selectedValue", this.selectedValue);
      this.generateStyle();
    },
    externalToggle() {
      if (this.externalToggle === this.options[0]) {
        this.toggleActive = false;
        //this.selectedValue = this.values[0];
      } else {
        this.toggleActive = true;
        //this.selectedValue = this.values[1];
      }
    },
  },
};
</script>
