<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
    :id="'counter-' + id"
    class="flex w-full flex-col text-center mb-10 md:mb-0 items-center"
  >
    <span
      class="text-xs w-4/6 h-8 font-light uppercase xl:mb-2 text-gray-500"
      >{{ header }}</span
    >
    <span :id="'counter-number-' + id"></span>
    <span class="text-xs mt-4"> {{ subNumber }}</span>
  </div>
</template>

<script>
// * External
import * as Odometer from "odometer/odometer.min.js";
// import "odometer/themes/odometer-theme-default.css";

export default {
  name: "Counter",
  props: {
    week_nr: {},
    loadingStates: {
      type: Object,
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    header: {
      type: String,
      required: true,
      default: "",
    },
    countNumber: {
      type: Number,
      required: true,
      default: 0,
    },
    subNumber: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      ol: null,
      inViewport: {
        now: false,
      },
      number: 0,
      numberOfLikes: 100,
      isVisible: false,
    };
  },
  methods: {
    update() {
      this.ol.update(this.countNumber);
    },
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
      // console.log(entry);
    },
  },
  async mounted() {
    let el = document.querySelector("#counter-number-" + this.id);

    this.ol = new Odometer({
      el: el,
      value: 0,
      format: "( ddd)",
      theme: "default",
      duration: 2000,
    });
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        this.update();
      }
    },
    countNumber() {
      this.update();
    },
  },
};
</script>

<style lang="postcss">
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  /* *zoom: 1;
  *display: inline; */
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  /* *zoom: 1;
  *display: inline; */
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  /* *zoom: 1;
  *display: inline; */
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  @apply overflow-y-hidden;
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: -3px;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-default
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  /* font-family: "Helvetica Neue", sans-serif; */
  @apply font-bold text-5xl xl:text-7xl;
  /* line-height: 1.4em; */
}
.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
  text-align: center;
}
</style>
