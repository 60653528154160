<template>
  <div class="flex flex-col w-full items-center">
    <div class="flex flex-col w-full bg-white items-center text-center">
      <div class="pb-1 text-md md:text-2xl font-bold items-center px-6 md:p-0">
        Stemningen som ble skapt blant partienes publikum i
        <SetWeek color="normal" class="ml-1 inline-flex md:relative" />
      </div>
      <span class="flex text-xs md:text-base pb-2 md:pb-5 font-normal">{{
        selectedScopeSubTitle
      }}</span>

      <div class="flex flex-col w-full bg-gray-100 p-2">
        <div class="flex w-full relative justify-center">
          <ToggleSwitchMenu
            :values="toggleValues"
            :options="selectedDataTypesValues.emojiReactions"
            @selectedValue="setToggleValue"
            :externalToggle="selectedDataTypes.emojiReactions"
            id="emojis"
          />
          <div
            class="hidden lg:flex z-20 absolute top-0 right-0 mr-0 mt-1 md:mt-0"
          >
            <img
              :src="logo"
              alt="Laget av Analyse &amp; Tall"
              title="Laget av Analyse &amp; Tall"
              class="h-6 sm:h-8 md:h-10 w-auto"
            />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="relative flex flex-col h-80 bg-gray-100 mb-2">
            <div
              v-if="loadingStates.emojis && loadingStates.relInteractions"
              class="flex w-full h-full justify-center items-center content-center"
            >
              Laster...
            </div>
            <div
              v-else-if="
                !loadingStates.emojis && !loadingStates.relInteractions
              "
              class="flex w-full h-full flex-col items-center justify-center content-center"
            >
              <span class="flex font-semibold pt-3 md:text-xl">{{
                printPartyName
              }}</span>
              <div
                class="flex w-full h-full items-center justify-center content-center overflow-hidden"
              >
                <div
                  class="flex relative w-full h-full"
                  v-if="
                    selectedParty !== null &&
                      selectedDataTypes.emojiReactions === 'comments'
                  "
                >
                  <Circles
                    :partyEmojis="partyEmojisPercentage"
                    :partyEmojisTotal="partyEmojisTotal"
                    :totalEmojis="parseInt(selectedPartyEmojiInfo['num_emoji'])"
                    class="-mt-5"
                  />
                  <!-- <template v-for="(item, index) in partyEmojis">
                  <div
                    :key="index"
                    class="flex items-center justify-center content-center"
                    :style="`width:${commentsEmojiSize(item[1])}%!important`"
                  >
                    <span
                      v-tooltip="{
                        content: 'Andel: ' + commentsEmojiSize(item[1]) + '%',
                        placement: 'top',
                        trigger: 'hover click',
                        autoHide: false,
                        show: index === 0 ? true : false,
                        hideOnTargetClick: false,
                      }"
                      v-if="item[0] !== '🇳🇴'"
                      :style="`font-size:${commentsEmojiSize(item[1])}%`"
                      class="transform-gpu transition-all duration-500 ease-out"
                      >{{ item[0] }}
                    </span>
                    <span
                      v-else
                      class="transform-gpu transition-all duration-500 ease-out"
                      :style="`font-size:${commentsEmojiSize(item[1])}%`"
                    >
                      <Norway />
                    </span>
                  </div>
                </template> -->
                </div>
                <p
                  class="flex flex-row items-center"
                  :style="emojiSize"
                  v-else-if="
                    selectedParty !== null &&
                      selectedDataTypes.emojiReactions === 'posts'
                  "
                >
                  <span
                    class="flex transform-gpu transition-all duration-500"
                    :style="dataStyle('love_sum')"
                    v-tooltip="{
                      content:
                        'Andel: ' + Math.round(emojiTypeSize('love_sum')) + '%',
                      placement: 'top',
                      trigger: 'hover click',
                    }"
                    >❤️</span
                  >
                  <span
                    class="flex transform-gpu transition-all duration-500"
                    :style="dataStyle('haha_sum')"
                    v-tooltip="{
                      content:
                        'Andel: ' + Math.round(emojiTypeSize('haha_sum')) + '%',
                      placement: 'top',
                      trigger: 'hover click',
                    }"
                    >😂</span
                  >
                  <span
                    class="flex transform-gpu transition-all duration-500"
                    :style="dataStyle('wow_sum')"
                    v-tooltip="{
                      content:
                        'Andel: ' + Math.round(emojiTypeSize('wow_sum')) + '%',
                      placement: 'top',
                      trigger: 'hover click',
                    }"
                    >😯</span
                  >
                  <span
                    class="flex transform-gpu transition-all duration-500"
                    :style="dataStyle('sad_sum')"
                    v-tooltip="{
                      content:
                        'Andel: ' + Math.round(emojiTypeSize('sad_sum')) + '%',
                      placement: 'top',
                      trigger: 'hover click',
                    }"
                    >😢</span
                  >
                  <span
                    class="flex transform-gpu transition-all duration-500"
                    :style="dataStyle('angry_sum')"
                    v-tooltip="{
                      content:
                        'Andel: ' +
                        Math.round(emojiTypeSize('angry_sum')) +
                        '%',
                      placement: 'top',
                      trigger: 'hover click',
                    }"
                    >😠</span
                  >
                </p>
                <p v-else>Velg et parti under.</p>
              </div>
            </div>
            <div
              class="flex relative w-full text-xs font-normal pr-3 pb-3 text-center justify-center"
              v-if="selectedDataTypes.emojiReactions === 'comments'"
            >
              <!-- Viser kun 10 mest brukte emojier i partiets kommentarfelt.<br /> -->
              <span class="flex text-center" v-if="selectedParty !== null">
                {{
                  parseInt(
                    selectedPartyEmojiInfo["unique_emoji"]
                  ).toLocaleString()
                }}
                unike emojier ble brukt
                {{
                  parseInt(selectedPartyEmojiInfo["num_emoji"]).toLocaleString()
                }}
                ganger i
                {{
                  parseInt(selectedPartyEmojiInfo["num_posts"]).toLocaleString()
                }}
                kommentarer.
              </span>
            </div>
          </div>
          <div
            class="flex justify-center flex-row px-6 mb-3 md:mx-6 lg:mx-16 flex-wrap"
          >
            <template v-for="(item, index) in listParties">
              <div
                :key="index"
                class="flex w-10 h-10 lg:w-12 lg:h-12 mr-1"
                @click="toggleParty(item)"
              >
                <PartyLogo
                  :party="item"
                  :circle="true"
                  :grayscale="true"
                  :clickable="true"
                  v-tooltip="{
                    content: partyNames[item].name,
                    placement: 'top',
                  }"
                  :active="selectedParty === item ? true : false"
                />
              </div>
            </template>
          </div>
          <div class="flex lg:hidden w-full relative">
            <div
              class="flex absolute right-0 self-end mb-1 mr-1 sm:mb-2 sm:mr-1"
            >
              <img
                :src="logo"
                alt="Laget av Analyse &amp; Tall"
                title="Laget av Analyse &amp; Tall"
                class="h-6 sm:h-8 md:h-10 w-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full self-start p-2 sm:pl-0 sm:pt-1 sm:pb-0">
        <p
          class="font-light text-xs text-darkblack pt-1 pr-1 break-normal text-left"
        >
          <span class="font-semibold">Datagrunnlag:</span>
          {{ selectedScopeDescription }} Kilde: Analyse &amp; Tall 2021.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="postcss"></style>

<script>
// * Store
import { mapActions, mapGetters } from "vuex";

// * Components
import PartyLogo from "@/components/PartyLogo.vue";
import partyNames from "@/assets/parties/parties.json";
import SetWeek from "@/components/SetWeek.vue";
import ToggleSwitchMenu from "@/components/ToggleSwitchMenu";
import Circles from "@/components/Circles.vue";

export default {
  name: "WeekEmojiReactions",
  components: { PartyLogo, ToggleSwitchMenu, SetWeek, Circles },
  props: {
    // week_nr: {},
    logo: {},
    // loadingStates: {
    //   type: Object,
    // },
  },
  data() {
    return {
      loaded: false,
      data: [],
      emojiData: { posts: [], comments: [] },
      partyNames,
      selectedParty: "R",
      partyEmojis: [],
      selectedPartyEmojiInfo: [], // Holds detailed info on emoji usage by party for the selected week.
      partyEmojisTotal: 0,
      // partyEmojisPercentage: [],
      toggleValues: ["Reaksjoner til innlegg", "Reaksjoner i kommentarer"],
      // selectedDataType: "posts",
      selectedData: null,
      postsData: [],
      allEmojisData: {}, // Holds all our weeks with data
      selectedWeekEmojisData: null, // Holds our selected week of data
      selectedPartyData: null,
    };
  },
  computed: {
    ...mapGetters("data", {
      selectedWeekRelativeWeekData: "getSelectedWeekRelativeWeekData",
      loadingStates: "getLoading",
      selectedWeek: "getSelectedWeek",
      selectedDataTypes: "getSelectedDataTypes",
      selectedDataTypesValues: "getSelectedDataTypesValues",
    }),
    selectedScopeSubTitle() {
      if (this.selectedDataTypes.emojiReactions === "posts") {
        return "(målt i andel ❤️,😂,😯,😢 og 😠 til innleggene)";
      } else {
        return "(målt i de 10 mest brukte emojiene i kommentarfeltene)";
      }
    },
    selectedScopeDescription() {
      if (this.selectedDataTypes.emojiReactions === "posts") {
        return `Andelen av emoji-reaksjoner (likes holdt utenfor) gitt til alle Facebook-innlegg fra sider med partitilhørighet i uke ${this.selectedWeek}. `;
      } else {
        return `Fordelingen av de 10 mest brukte emojiene i kommentarfeltene til de forskjellige partiene i uke ${this.selectedWeek}.`;
      }
    },
    // selectedPartyData() {
    //   return this.postsData.find((x) => x.party_abbr === this.selectedParty);
    // },
    listParties() {
      let parties = [];
      for (const [key] of Object.entries(this.partyNames)) {
        parties.push(key);
      }
      return parties;
    },
    // canvasSize() {
    //   if (this.selectedParty === null) {
    //     return {
    //       "font-size": "100%",
    //     };
    //   } else {
    //     return {
    //       "font-size": "2500%",
    //     };
    //   }
    // },
    printPartyName() {
      let name;
      if (this.selectedParty !== null) {
        name = this.partyNames[this.selectedParty].name;
      }
      return name;
    },
    // countryFlag() {
    //   return "NO".replace(/./g, (x) =>
    //     String.fromCharCode(55356, 56741 + x.charCodeAt(0))
    //   );
    // },
    // For post emojis
    emojiSize() {
      if (
        this.selectedParty === null ||
        this.selectedParty === "undefined" ||
        this.selectedPartyData === "undefined" ||
        this.selectedPartyData === null
      ) {
        return {
          "font-size": "100%",
        };
      } else {
        return {
          "font-size": "1200%",
        };
      }
    },
    // For post emojis
    // likeShare() {
    //   let percentage;
    //   if (
    //     this.selectedParty === null ||
    //     this.selectedParty === "undefined" ||
    //     this.selectedPartyData === "undefined" ||
    //     this.selectedPartyData === null
    //   ) {
    //     percentage = 0;
    //   } else {
    //     percentage =
    //       (this.selectedPartyData.like_sum /
    //         this.selectedPartyData.total_interactions_sum) *
    //       100;
    //   }
    //   return percentage;
    // },
    partyEmojisPercentage() {
      let self = this;
      let l = [];
      this.partyEmojis.forEach(function(el) {
        l.push([
          el[0],
          Math.round((parseInt(el[1]) / self.partyEmojisTotal) * 100),
        ]);
      });
      return l;
    },
  },
  methods: {
    ...mapActions("data", {
      getEmojis: "getEmojis",
    }),
    async loadEmojis() {
      await this.getEmojis().then((response) => {
        this.selectedWeekEmojisData = response;
        this.buildData();
        if (this.selectedWeek in this.allEmojisData) {
          //
        } else {
          this.allEmojisData[this.selectedWeek] = this.selectedWeekEmojisData;
        }
      });
    },
    setToggleValue(value) {
      if (value === this.toggleValues[0]) {
        this.selectedDataTypes.emojiReactions = "posts";
      } else if (value === this.toggleValues[1]) {
        this.selectedDataTypes.emojiReactions = "comments";
      }
    },
    toggleParty(party) {
      if (this.selectedParty === null) {
        this.selectedParty = party;
      } else if (this.selectedParty === party) {
        this.selectedParty = null;
      } else {
        this.selectedParty = party;
      }
    },
    commentsEmojiSize(val) {
      let percentage;
      if (this.selectedParty !== null) {
        percentage = Math.round((val / this.partyEmojisTotal) * 100);
      } else {
        percentage = 100;
      }
      return percentage;
      //return { "font-size": `${percentage}%` };
    },
    // For post emjois - badly implemented, this is called 10 times for every interaction
    emojiTypeSize(reactionType) {
      let percentage;
      if (this.selectedParty === null) {
        percentage = 0;
      } else if (this.selectedParty !== null && this.postsData.length > 0) {
        percentage =
          (this.selectedPartyData[reactionType] /
            (this.selectedPartyData.love_sum +
              this.selectedPartyData.haha_sum +
              this.selectedPartyData.wow_sum +
              this.selectedPartyData.sad_sum +
              this.selectedPartyData.angry_sum)) *
          100;
      }
      return percentage;
    },
    // For post emjois
    dataStyle(reactionType) {
      if (this.selectedParty === null) {
        return { "font-size": "100%" };
      } else {
        let size = this.emojiTypeSize(reactionType);
        return {
          "font-size": `${size}%`,
        };
      }
    },
    buildData() {
      if (this.selectedParty !== null) {
        if (this.selectedDataTypes.emojiReactions === "comments") {
          // this.selectedData
          let party = this.selectedWeekEmojisData.filter((obj) => {
            return obj.party_abbr === this.selectedParty;
          });
          this.selectedPartyEmojiInfo = party[0];
          this.partyEmojis = party[0].json_field;

          // Sum the values of each party to get a total:
          let s = (r, a) => r.map((b, i) => a[i] + b);
          this.partyEmojisTotal = this.partyEmojis.reduce(s)[1];
        } else if (
          this.selectedDataTypes.emojiReactions === "posts" &&
          this.selectedWeekRelativeWeekData !== null
        ) {
          this.postsData = this.selectedWeekRelativeWeekData.all_pages;
          this.selectedPartyData = this.postsData.find(
            (x) => x.party_abbr === this.selectedParty
          );
        }
      } else {
        this.partyEmojis = [];
        this.partyEmojisTotal = 0;
      }
    },
  },
  async mounted() {
    if (this.selectedWeek !== null && this.selectedWeek !== "undefined") {
      this.loadEmojis();
    }
  },
  watch: {
    selectedWeek() {
      if (this.selectedWeek in this.allEmojisData) {
        // Switch the selected data to the relevant data - we need to rebuild the data first:
        this.selectedWeekEmojisData = this.allEmojisData[this.selectedWeek];
        this.buildData();
      } else {
        // Run a new API call to get the week we don't have yet
        this.loadEmojis();
      }
    },
    selectedParty() {
      this.buildData();
    },
    "selectedDataTypes.emojiReactions": function() {
      this.buildData();
    },
  },
};
</script>
