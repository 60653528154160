import ApolloClient from "apollo-boost";
import store from "@/store";
// import { handleRequestError } from "@/mixins/helpers"

// const onError = ({ graphQLErrors, networkError, response }) => {
//   response.errors = null
//   if (graphQLErrors) {
//     graphQLErrors.map(error => {
//       if (
//         ["LOGIN_INVALID", "LOGIN_INACTIVE", "PASSWORD_EXPIRED"].includes(
//           error.message
//         )
//       ) {
//         store.dispatch("auth/setLoginError", error.message)
//       } else if (error.message === "LOGIN_BLOCKED") {
//         store.dispatch("auth/setLoginError", error.message)
//         store.dispatch("auth/setBlockedUntil", error.extensions.blockedUntil)
//       } else if (error.message === "TOKEN_INVALID") {
//         store.dispatch("auth/logout")
//       } else if (
//         [
//           "LOGIN_REQUIRED",
//           "ADMIN_RIGHTS_REQUIRED",
//           "DEV_RIGHTS_REQUIRED",
//         ].includes(error.message)
//       ) {
//         response.errors = null
//         handleRequestError(error.message)
//         throw error
//       } else {
//         // Throw error
//         console.log(
//           `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
//         )
//       }
//     })
//   }
//   if (networkError) console.log(`[Network error]: ${networkError}`)
// }

// Error Handling for apollo - strongly advised!
const onError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      if (error.message === "jwt expired") {
        // Log out if token experied // TODO: Check if this actually is working
        store.dispatch("logout");
        this.$router.push({
          path: "/login",
          query: {
            message: "You token have experied. Please login again.",
          },
        });
      } else {
        // Throw error
        console.log(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
        );
      }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
};

export const apolloClient = new ApolloClient({
  uri: "/.netlify/functions/graphql",
  request: (operation) => {
    const token = localStorage.getItem("authToken");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
  onError,
});
