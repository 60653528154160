// We'll abandon this when the database is up running.
import gql from "graphql-tag";
import { apolloClient } from "../apollo.js";
// import { ConvertMinutes } from "@/mixins/helpers.js";

const state = {
  partyQuery: { id: 1 },
  loading: {
    parties: true,
    weekSummary: true,
    topInteractions: true,
    relInteractions: true,
    emojis: true,
  },
  selectedWeek: null,
  allWeeks: [], // To be populated when we get data from DB - a list of all our weeks
  allWeeksData: [],
  selectedWeekData: null,
  allRelativeWeekData: {},
  selectedWeekRelativeWeekData: null,
  allTopWeekData: {},
  selectedWeekTopWeekData: null,
  minCollection: null,
  maxCollection: null,
  avgCollection: null,
  selectedDataTypes: {
    averageEngagement: "all_pages", // only_party_leaders
    topEngagement: "only_org_posts", // all_posts
    emojiReactions: "posts", // comments
  },
  selectedDataTypesValues: {
    averageEngagement: ["all_pages", "only_party_leaders"],
    topEngagement: ["only_org_posts", "all_posts"],
    emojiReactions: ["posts", "comments"],
  },
};

const mutations = {
  SET_SELECTED_DATA_TYPE: (state, val) => {
    state.selectedDataTypes[val.type] = val.value;
  },
  SET_LOADING_PARTIES: (state, val) => {
    state.loading.parties = val;
  },
  SET_LOADING_WEEK_SUMMARY: (state, val) => {
    state.loading.weekSummary = val;
  },
  SET_LOADING_TOP_INTERACTIONS: (state, val) => {
    state.loading.topInteractions = val;
  },
  SET_LOADING_REL_INTERACTIONS: (state, val) => {
    state.loading.relInteractions = val;
  },
  SET_LOADING_EMOJIS: (state, val) => {
    state.loading.emojis = val;
  },
  SET_ALL_WEEKS: (state, val) => {
    state.allWeeks = val;
  },
  SET_ALL_WEEK_DATA: (state, val) => {
    state.allWeeksData = val;
  },
  SET_SELECTED_WEEK_DATA: (state, val) => {
    state.selectedWeekData = val;
  },
  SET_WEEK: (state, val) => {
    state.selectedWeek = parseInt(val);
  },
  SET_RELATIVE_INTERACTIONS: (state, val) => {
    if (val.week_nr in state.allRelativeWeekData) {
      // We already have it
    } else {
      state.allRelativeWeekData[val.week_nr] = val.data;
    }
  },
  SET_SELECTED_WEEK_RELATIVE_INTERACTIONS_DATA: (state, val) => {
    state.selectedWeekRelativeWeekData = val;
  },
  SET_TOP_INTERACTIONS: (state, val) => {
    if (val.week_nr in state.allTopWeekData) {
      // We already have it
    } else {
      state.allTopWeekData[val.week_nr] = val.data;
    }
  },
  SET_SELECTED_WEEK_TOP_INTERACTIONS_DATA: (state, val) => {
    state.selectedWeekTopWeekData = val;
  },
  // SET_MIN_COLLECTION: (state, val) => {
  //   state.minCollection = val;
  // },
  // SET_MAX_COLLECTION: (state, val) => {
  //   state.maxCollection = val;
  // },
  // SET_AVG_COLLECTION: (state, val) => {
  //   state.avgCollection = val;
  // },
};

const actions = {
  async getParties({ commit, state, dispatch }) {
    await dispatch("getWeeks");

    // async getParties({ commit, state, rootGetters }) {
    // Get a lits of the parties
    const response = await apolloClient.query({
      query: gql`
        query getParties {
          getParties {
            party_abbr
            party_name
            party_leader
            party_leader_page_id
            sort
            total_party_pages
          }
        }
      `,
      variables: state.partyQuery,
    });

    const { loading, error, data } = response;
    if (error) console.log(`Error! ${error.message}`);
    if (!loading) {
      commit("SET_LOADING_PARTIES", false);
    }

    if (data.getParties.length > 0) {
      // Sort data from left to right on the political spectrum:
      return data.getParties.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    } else {
      return null;
    }
  },
  async getWeeks({ commit }) {
    // async getParties({ commit, state, rootGetters }) {
    // Get a lits of the parties
    commit("SET_LOADING_WEEK_SUMMARY", true);
    const response = await apolloClient.query({
      query: gql`
        query getWeeks($limit: Int) {
          getWeeks(limit: $limit) {
            week_nr
            year
            start_date
            end_date
            prev_week_nr
            pages
            posts
            total_interactions
            comments
            likes
            shares
            prev_pages
            prev_posts
            prev_total_interactions
            prev_comments
            prev_shares
            prev_likes
            min_post_collection_span
            avg_post_collection_span
            max_post_collection_span
          }
        }
      `,
      variables: { limit: null },
    });

    const { loading, error, data } = response;
    if (error) console.log(`Error! ${error.message}`);
    if (!loading) {
      commit("SET_LOADING_WEEK_SUMMARY", false);
    }

    if (data.getWeeks.length > 0) {
      // Store all the raw data in a variable
      commit("SET_ALL_WEEK_DATA", data.getWeeks);

      // Only week number representation - not data for the weeks:
      let allWeeks = data.getWeeks
        .slice(0, 5)
        .map((obj) => parseInt(obj.week_nr));
      commit("SET_ALL_WEEKS", allWeeks);

      // Set the current week (eg. last week) - selectedWeek
      commit("SET_WEEK", allWeeks[0]);

      // // Set current selected week data:
      // let selectedWeekData = data.getWeeks.filter((obj) => {
      //   return obj.week_nr === state.selectedWeek;
      // });
      // commit("SET_SELECTED_WEEK_DATA", selectedWeekData[0]);

      // commit(
      //   "SET_MIN_COLLECTION",
      //   ConvertMinutes(selectedWeekData[0]["min_post_collection_span"])
      // );
      // commit(
      //   "SET_MAX_COLLECTION",
      //   ConvertMinutes(selectedWeekData[0]["max_post_collection_span"])
      // );
      // commit(
      //   "SET_AVG_COLLECTION",
      //   ConvertMinutes(selectedWeekData[0]["avg_post_collection_span"])
      // );
    }
  },
  async getTopInteractions({ commit, state }) {
    if (state.selectedWeek in state.allTopWeekData) {
      commit("SET_LOADING_TOP_INTERACTIONS", true);
      commit(
        "SET_SELECTED_WEEK_TOP_INTERACTIONS_DATA",
        state.allTopWeekData[state.selectedWeek]
      );
      commit("SET_LOADING_TOP_INTERACTIONS", false);
      return state.allTopWeekData[state.selectedWeek];
      // We have the week data, so no need to run a new query, just return relevant data
    } else {
      // Get a lits of top posts by interactions
      commit("SET_LOADING_TOP_INTERACTIONS", true);

      const response = await apolloClient.query({
        query: gql`
          query getTopInteractions($year: Int!, $week_nr: Int!, $limit: Int) {
            getTopInteractions(year: $year, week_nr: $week_nr, limit: $limit) {
              week_nr
              selection
              page_name
              page_level
              date
              party_abbr
              page_likes
              fb_post_id
              page_id
              message
              total_interactions
              total_likes
              like_count
              share_count
              comments
            }
          }
        `,
        variables: {
          limit: 100,
          year: 2021,
          week_nr: parseInt(state.selectedWeek),
        },
      });

      const { loading, error, data } = response;
      if (error) console.log(`Error! ${error.message}`);
      if (!loading) {
        commit("SET_LOADING_TOP_INTERACTIONS", false);
      }

      if (data.getTopInteractions.length > 0) {
        // Sort data on total interactions descending
        let l = data.getTopInteractions.sort((a, b) =>
          a.total_interactions > b.total_interactions ? -1 : 1
        );

        let groupedResult = l.reduce(function(r, a) {
          r[a.selection] = r[a.selection] || [];
          r[a.selection].push(a);
          return r;
        }, Object.create(null));
        commit("SET_TOP_INTERACTIONS", {
          week_nr: parseInt(state.selectedWeek),
          data: groupedResult,
        });
        commit("SET_SELECTED_WEEK_TOP_INTERACTIONS_DATA", groupedResult);
        return groupedResult;
      } else {
        return null;
      }
    }
  },
  async getRelInteractions({ commit, state }) {
    if (state.selectedWeek in state.allRelativeWeekData) {
      commit("SET_LOADING_REL_INTERACTIONS", true);
      commit(
        "SET_SELECTED_WEEK_RELATIVE_INTERACTIONS_DATA",
        state.allRelativeWeekData[state.selectedWeek]
      );
      commit("SET_LOADING_REL_INTERACTIONS", false);
      return state.allRelativeWeekData[state.selectedWeek];
      // We have the week data, so no need to run a new query, just return relevant data
    } else {
      // Get a lits of top posts by interactions
      commit("SET_LOADING_REL_INTERACTIONS", true);

      const response = await apolloClient.query({
        query: gql`
          query getRelInteractions($year: Int!, $week_nr: Int!, $limit: Int) {
            getRelInteractions(year: $year, week_nr: $week_nr, limit: $limit) {
              week_nr
              selection
              page_name
              party_abbr
              sort
              total_interactions_avg
              total_interactions_sum
              posts
              pages
              ratio
              angry_sum
              love_sum
              like_sum
              haha_sum
              wow_sum
              sad_sum
            }
          }
        `,
        variables: {
          limit: 18,
          year: 2021,
          week_nr: parseInt(state.selectedWeek),
        },
      });

      const { loading, error, data } = response;
      if (error) console.log(`Error! ${error.message}`);
      if (!loading) {
        commit("SET_LOADING_REL_INTERACTIONS", false);
      }

      if (data.getRelInteractions.length > 0) {
        // Sort data on total interactions descending
        let l = data.getRelInteractions.sort((a, b) =>
          a.sort > b.sort ? 1 : -1
        );

        let groupedResult = l.reduce(function(r, a) {
          r[a.selection] = r[a.selection] || [];
          r[a.selection].push(a);
          return r;
        }, Object.create(null));
        commit("SET_RELATIVE_INTERACTIONS", {
          week_nr: parseInt(state.selectedWeek),
          data: groupedResult,
        });
        commit("SET_SELECTED_WEEK_RELATIVE_INTERACTIONS_DATA", groupedResult);
        return groupedResult;
      } else {
        return null;
      }
    }
  },
  async getEmojis({ commit, state }) {
    // Get a lits 10 most used emojis for each party
    commit("SET_LOADING_EMOJIS", true);

    const response = await apolloClient.query({
      query: gql`
        query getEmojis($year: Int!, $week_nr: Int!, $limit: Int) {
          getEmojis(year: $year, week_nr: $week_nr, limit: $limit) {
            selection
            party_abbr
            json_field
            num_posts
            num_emoji
            unique_emoji
            emoji_per_post
          }
        }
      `,
      variables: {
        year: 2021,
        week_nr: parseInt(state.selectedWeek),
      },
    });

    const { loading, error, data } = response;
    if (error) console.log(`Error! ${error.message}`);
    if (!loading) {
      commit("SET_LOADING_EMOJIS", false);
    }

    if (data.getEmojis.length > 0) {
      return data.getEmojis;
    } else {
      return null;
    }
  },
};

const getters = {
  getLoading: (state) => state.loading,
  getAllWeeks: (state) => state.allWeeks,
  getAllWeeksData: (state) => state.allWeeksData, // Used in the intro to get the current week data
  getSelectedWeek: (state) => state.selectedWeek,
  //getSelectedWeekData: (state) => state.selectedWeekData, // Not used

  getAllRelativeWeekData: (state) => state.allRelativeWeekData,
  getSelectedWeekRelativeWeekData: (state) =>
    state.selectedWeekRelativeWeekData,

  getAllTopWeekData: (state) => state.allTopWeekData,
  getSelectedWeekTopWeekData: (state) => state.selectedWeekTopWeekData,

  // getMinCollection: (state) => state.minCollection,
  // getMaxCollection: (state) => state.maxCollection,
  // getAvgCollection: (state) => state.avgCollection,

  getSelectedDataTypes: (state) => state.selectedDataTypes,
  getSelectedDataTypesValues: (state) => state.selectedDataTypesValues,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
