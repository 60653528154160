<template>
  <div
    class="party-logo-container"
    :class="[
      circle ? 'party-logo-circle' : '',
      grayscale ? 'grayscale-img' : '',
      active ? 'active' : '',
      clickable
        ? 'cursor-pointer hover:shadow-lg transform-gpu transition-all duration-500 hover:scale-110 hover:ease-in-out'
        : '',
    ]"
  >
    <div class="party-logo-image">
      <img :src="partyLogo(party)" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.party-logo-container {
  @apply flex relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.party-logo-container:hover {
  z-index: 999;
}
.party-logo-container.active {
  @apply shadow-lg transform-gpu transition-all duration-500 scale-110;
  filter: grayscale(0%) !important;
}
.party-logo-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.party-logo-image .img {
  width: 100%;
}
.party-logo-circle {
  @apply rounded-full bg-white;
}
/* .party-logo-circle:hover {
  @apply shadow-lg transform-gpu transition-all duration-500 scale-110 ease-in-out;
} */
.party-logo-circle .party-logo-image {
  @apply rounded-full;
  width: 90%;
  height: 90%;
}
.grayscale-img {
  @apply transform transition duration-500 ease-in-out;
  filter: grayscale(100%);
}
.grayscale-img:hover {
  filter: grayscale(0%);
}
</style>

<script>
import partyNames from "@/assets/parties/parties.json";
export default {
  props: {
    party: {},
    circle: {
      default: false,
    },
    grayscale: {
      default: false,
    },
    active: {
      default: false,
    },
    clickable: {
      default: true,
    },
  },
  data() {
    return {
      partyNames,
    };
  },
  methods: {
    partyLogo: function(party) {
      return require(`@/assets/parties/${party}.png`);
    },
  },
};
</script>
