<template>
  <div
    role="presentation"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <accordion-item
      v-for="(item, id) in content"
      :multiple="multiple"
      :item="item"
      :groupId="groupId"
      :key="id"
      :barSize="calculateBarSize(parseInt(item[barValueElement]))"
      :useBorder="useBorder"
      :monotone="monotone"
      :isVisible="isVisible"
    >
    </accordion-item>
  </div>
</template>

<script>
import AccordionItem from "./AccordionItem";
export default {
  name: "VueTailwindAccordion",
  components: {
    AccordionItem,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    useBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    monotone: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      groupId: null,
      reformatData: [],
      // minBarSize: 35, // In percentages
      // maxBarSize: 100, // In percentages
      barValueElement: "total_interactions", // top_interactions_rel
      isVisible: false,
    };
  },
  computed: {
    accordionClasses: function() {
      return {
        "is-closed": !this.isOpen,
        "is-primary": this.isOpen,
        "is-dark": !this.isOpen,
      };
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
    },
    toggleAccordion: function() {
      this.isOpen = !this.isOpen;
    },
    calculateBarSize(val) {
      let element = this.barValueElement;
      let minVal = this.getMinValue(this.content, element);
      let maxVal = this.getMaxValue(this.content, element);
      let minBarSize = (minVal / maxVal) * 100;
      let maxBarSize = 100;

      let res =
        minBarSize +
        ((val - minVal) * (maxBarSize - minBarSize)) / (maxVal - minVal);
      return res;
    },
    getMinValue(data, element) {
      return data.reduce(
        (min, p) => (p[element] < min ? p[element] : min),
        data[0][element]
      );
    },
    getMaxValue(data, element) {
      return data.reduce(
        (max, p) => (p[element] > max ? p[element] : max),
        data[0][element]
      );
    },
  },
  mounted() {
    this.groupId = this.$el.id;
  },
};
</script>
