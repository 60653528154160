<template>
  <div class="flex flex-col w-full mb-10 items-center px-3 sm:px-6">
    <div
      class="flex flex-col w-full bg-gray-100 rounded-md"
      :class="[
        !showContent
          ? 'md:w-3/4 lg:w-3/5 xl:w-2/5'
          : 'md:w-3/4 lg:w-3/5 xl:w-4/5',
      ]"
    >
      <span
        @click="showContent = !showContent"
        class="text-base md:text-xl font-bold text-center py-2 text-darkblack cursor-pointer"
        >Metode og datakilder</span
      >
      <transition
        name="dropdown"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div v-if="showContent" class="overflow-hidden p-6 bg-gray-100">
          <p class="text-sm md:text-base mb-5 leading-relaxed">
            Alle data som vises på denne siden er samlet inn fra offentlig
            tilgjengelige sider på Facebook. Spesifikt viser vi frem innsikter
            basert på data for partier som er valgt inn på Stortinget i dag fra
            følgende Facebook-sider:
          </p>

          <ul
            class="list-disc list-inside text-sm md:text-base mb-5 leading-relaxed"
          >
            <li>Nasjonale partisider</li>
            <li>Partienes fylkeslag</li>
            <li>Partiledere, nestledere og partisekretærer</li>
            <li>Stortingspolitikere</li>
            <li>Partienes 5 øverste Stortingskadidater fra hver valgkrets</li>
            <li>Ungdomspartiene nasjonalt</li>
          </ul>

          <p class="text-sm md:text-base mb-5 leading-relaxed">
            For hvert Facebook-innlegg fra disse sidene har vi også samlet inn
            antall kommentarer, delinger og mottatte interaksjoner til
            innlegget. Før februar 2016 opererte Facebook kun med likes. I
            februar 2016 ble reaksjonene ❤️,😂,😯,😢 og 😠 innført på
            plattformen. Totale interaksjoner utgjør summen av alle likes,
            emoji-reaksjoner, kommentarer og delinger til Facebook-innlegg.
          </p>

          <p class="text-sm md:text-base mb-5 leading-relaxed">
            Data som vises her samles inn fortløpende gjennom en uke og er en
            del av vår generelle politiske database for Norge. Denne inneholder
            alle sider og grupper for politiske partier,
            stortingsrepresentanter, ministre og talspersoner som har en
            offentlig profil på Facebook.
          </p>
          <p class="text-sm md:text-base mb-5 leading-relaxed">
            Våre data kan avvike noe fra det som til en hver tid er synlig på
            Facebook sine sider. Dette kan f.eks. komme av at konkrete innlegg
            eller kommentarer er slettet etter at de ble publisert, at sidene
            videredeler innhold fra privatpersoner eller fordi det kan fortsette
            å komme interaksjoner til et innlegg etter at vår innsamling er
            gjennomført. Erfaringsmessig kommer hovedandelen av interaksjoner
            innenfor de første 48 timene etter at et innlegg publiseres.
            Facebook synliggjør ikke om noen av innleggene er blitt promotert
            med kjøpt plassering fra partiene.
          </p>

          <p class="text-sm md:text-base mb-10 leading-relaxed">
            For uke {{ selectedWeek }} gikk det i gjennomsnitt
            <span class="font-semibold">{{ avgCollection }}</span> fra et
            innlegg ble publisert på Facebook til vi innhentet informasjon fra
            det.
          </p>

          <span
            class="text-base md:text-lg font-bold text-center mb-5 md:mb-10 text-darkblack"
            >Bruk av våre tall, analyser og grafikk</span
          >

          <p class="text-sm md:text-base leading-relaxed">
            Vi oppfordrer til gjenbruk av innholdet på denne siden, men ber om
            at alle tall, analyser og grafikker gjenbrukes med kildehenvisning
            til Analyse &amp; Tall. Om du er usikker på innholdet i det du
            ønsker å gjenbruke må du gjerne ta kontakt med oss.
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.3s ease-in;
}

.dropdown-enter,
.dropdown-leave-to {
  height: 0 !important;
}
</style>

<script>
// * Helpers
import { ConvertMinutes } from "@/mixins/helpers.js";

// * Store
import { mapGetters } from "vuex";

export default {
  name: "Methods",
  props: {
    // minCollection: {
    //   type: String,
    //   required: false,
    // },
    // maxCollection: {
    //   type: String,
    //   required: false,
    // },
    // avgCollection: {
    //   type: String,
    //   required: false,
    // },
  },
  data: () => ({
    showContent: false,
  }),
  computed: {
    ...mapGetters("data", {
      loadingStates: "getLoading",
      allWeeksData: "getAllWeeksData",
      selectedWeek: "getSelectedWeek",
      // minCollection: "getMinCollection",
      // maxCollection: "getMaxCollection",
      // avgCollection: "getAvgCollection",
    }),
    currentWeek: {
      get() {
        // Set data to the selected week:
        return this.allWeeksData.filter((obj) => {
          return obj.week_nr === parseInt(this.selectedWeek);
        })[0];
      },
    },
    minCollection() {
      return ConvertMinutes(this.currentWeek["min_post_collection_span"]);
    },
    maxCollection() {
      return ConvertMinutes(this.currentWeek["max_post_collection_span"]);
    },
    avgCollection() {
      return ConvertMinutes(this.currentWeek["avg_post_collection_span"]);
    },
  },
  methods: {
    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },
    endTransition(el) {
      el.style.height = "";
    },
  },
};
</script>
