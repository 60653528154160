<template>
  <div class="flex w-full h-64 bg-darkblack overflow-hidden justify-center">
    <div
      class="flex flex-col w-full max-w-screen-lg justify-center items-center content-center"
    >
      <div class="flex">
        <div class="flex">
          <a
            href="https://www.ogtall.no"
            onlick="fathom.trackGoal('NQ7SNHQH', 0);"
            target="_blank"
            ><img
              :src="currentLogo"
              alt="Laget av Analyse &amp; Tall"
              title="Laget av Analyse &amp; Tall"
              class="w-24"
              v-tooltip="{
                content:
                  'Hei👋 Vi heter Analyse & Tall. Vi er et analysebyrå som har spesialisert oss i å telle det som er vanskelig. Trykk her for å se mer av det vi vanligvis teller og analyserer.',
                placement: 'top',
              }"
          /></a>
        </div>
      </div>
      <div class="sm:flex flex-col pt-6">
        <SocialSharing />
      </div>
    </div>
  </div>
</template>

<script>
import SocialSharing from "@/components/SocialSharing.vue";

export default {
  name: "Footer",
  components: { SocialSharing },
  data() {
    return {
      logo: require("@/assets/Logo-ikon.hvid.png"),
    };
  },
  computed: {
    currentLogo() {
      // Still does not resolve pre-loading of the hover image
      let fileName;
      if (this.hoveredLogo) {
        fileName = "LogoNO-full.sorthvid-1";
      } else {
        fileName = "LogoNO-full.sorthvid-2";
      }
      return require(`@/assets/${fileName}.png`);
    },
  },
};
</script>
