<template>
  <div :id="groupId + '-' + id" :class="[item.active ? 'is-active' : null]">
    <div class="accordion-item-title">
      <div
        class="flex w-full flex-row text-left items-center bg-transparent border-transparent accordion-item-trigger pb-1"
      >
        <div
          class="flex flex-grow"
          @mouseover="active = true"
          @mouseleave="active = false"
          @click="active = !active"
        >
          <div
            class="flex w-2 mr-1"
            :style="`background-color:${allColors[item.party_abbr]}`"
          ></div>
          <div
            class="flex justify-between text-xs px-2 py-1 rounded-r-md overflow-hidden shadow-sm transition-all duration-700 text-white"
            :class="[
              item.posts >= 3 ? 'bg-gray-400' : 'bg-gray-300',
              item.posts >= 3 && active
                ? 'bg-gray-500 shadow-lg text-gray-200'
                : null,
            ]"
            :style="barStyle"
            v-tooltip="{
              content: `${item.posts} innlegg med totalt ${parseInt(
                item.total_interactions_sum
              ).toLocaleString()} interaksjoner.`,
              placement: 'top-start',
              trigger: 'manual',
              show: active,
            }"
          >
            <span
              class="flex accordion-item-title-text font-semibold truncate overflow-hidden overflow-ellipsis"
              >{{ showItemTitle }}</span
            >
            <span v-if="barSize > barSizeCutoff" class="text-white text-left">{{
              parseInt(item[barValueElement]).toLocaleString()
            }}</span>
          </div>
          <span
            v-if="barSize <= barSizeCutoff"
            class="pl-1 py-1 text-right truncate overflow-clip"
            :class="[
              parseInt(item[barValueElement]) === 0
                ? 'text-gray-300'
                : 'text-gray-500',
            ]"
            >{{ showItemDetails }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bar",
  props: [
    "item",
    "barSize",
    "multiple",
    "groupId",
    "partyNames",
    "selectedDataType",
    "barValueElement",
    "isVisible",
    "id",
  ],
  data: () => ({
    allColors: {
      R: "#990014",
      SV: "#d94abf",
      A: "#e51c30",
      SP: "#a5cd39",
      MDG: "#3d8704",
      KrF: "#f0b618",
      V: "#24b38c",
      H: "#00b9f2",
      FrP: "#005799",
      Sentrum: "purple",
    },
    remapNames: {
      "Bjørnar Moxnes - Rødt": "Bjørnar Moxnes",
    },
    barSizeCutoff: 30,
    active: false,
  }),
  computed: {
    animatedBarSize() {
      if (this.isVisible) {
        return this.barSize;
      } else {
        return 0;
      }
    },
    barStyle() {
      if (!this.monotone) {
        return {
          "background-color": `${this.item.color}`,
          width: `${this.animatedBarSize}%`,
        };
      } else {
        return {
          width: `${this.animatedBarSize}%`,
        };
      }
    },
    itemTitle() {
      if (this.selectedDataType === "all_pages") {
        // return this.partyNames[this.item.party_abbr].name;
        return this.item.party_abbr === "A" ? "AP" : this.item.party_abbr;
      } else {
        let name =
          this.item.page_name == "Bjørnar Moxnes - Rødt"
            ? this.remapNames[this.item.page_name]
            : this.item.page_name;
        return name;
      }
    },
    showItemTitle() {
      if (
        this.barSize <= this.barSizeCutoff &&
        this.selectedDataType === "only_party_leaders"
      ) {
        return "";
      }
      return this.itemTitle;
    },
    showItemDetails() {
      if (parseInt(this.item[this.barValueElement]) === 0) {
        return this.itemTitle; //+ ` - mindre enn 3 innlegg`;
      } else if (
        this.barSize <= this.barSizeCutoff &&
        this.selectedDataType === "only_party_leaders"
      ) {
        return (
          this.itemTitle +
          " - " +
          parseInt(this.item[this.barValueElement]).toLocaleString()
        );
      } else {
        return parseInt(this.item[this.barValueElement]).toLocaleString();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(10, 10, 10, 0.1);
}
.accordion-item:last-child .accordion-item-details {
  @apply rounded-b-md mb-0;
}

.accordion-item-title {
  @apply relative flex text-xs;
}

.accordion-item-trigger-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-right: 2px solid #363636;
  border-bottom: 2px solid #363636;
  transform: translateY(-2px) rotate(45deg);
  transition: transform 0.2s ease;
}
.is-active .accordion-item-trigger-icon {
  transform: translateY(2px) rotate(225deg);
}

.accordion-item-details {
  @apply text-xs overflow-hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}

.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>
