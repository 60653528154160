<template>
  <div class="flex relative">
    <OtSelect
      v-if="!loadingStates.weekSummary"
      id="selectWeek"
      :options="allWeeks"
      v-model="selectedWeekValue"
      @change="updateWeek"
      class="mr-1"
      :preText="preText"
      :showDropDownButton="showDropDownButton"
      :color="color"
      :inline="inline"
    ></OtSelect>
  </div>
</template>

<script>
// * Store
import { mapGetters, mapMutations } from "vuex";

// * Components
import OtSelect from "@/otComponents/OtSelect/OtSelect";

export default {
  name: "SetWeek",
  components: {
    OtSelect,
  },
  props: {
    preText: {
      type: Boolean,
      default: false,
    },
    showDropDownButton: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    inline: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("data", {
      loadingStates: "getLoading",
      selectedWeek: "getSelectedWeek",
      allWeeks: "getAllWeeks",
    }),
    selectedWeekValue: {
      get() {
        return this.selectedWeek;
      },
      set(value) {
        this.setWeek(value);
      },
    },
  },
  methods: {
    ...mapMutations("data", {
      setWeek: "SET_WEEK",
    }),
    updateWeek(week) {
      this.setWeek(week);
    },
  },
};
</script>
