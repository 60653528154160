<template>
  <div class="flex flex-col">
    <div class="flex flex-col w-full items-center">
      <div
        class="flex relative w-full flex-row content-center justify-center pb-1 md:pb-4"
      >
        <div class="flex flex-col justify-center items-center text-center">
          <div
            class="relative font-bold text-base md:text-xl px-6 pb-3 md:p-0 items-center"
          >
            Facebook-inlegg som skapte mest engasjement i
            <span class="relative">
              <SetWeek :color="'normal'" class="ml-1 inline-flex md:relative" />
            </span>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full bg-gray-100 text-left items-center">
        <div
          class="flex relative w-full justify-center items-center mb-3 md:mb-0 mt-3"
        >
          <div class="flex absolute top-0 left-0 text-sm">
            <OtSelect
              v-if="!loadingStates.weekSummary"
              id="selectAmount"
              :options="[10, 20]"
              v-model="topPostsNr"
              @change="updatePostNr($event)"
              :showDropDownButton="false"
              :weekText="false"
              :color="'normal'"
              class="relative p-0 w-8 ml-2 mt-0 md:w-12"
              :clicked="true"
            ></OtSelect>
          </div>
          <ToggleSwitchMenu
            :values="toggleValues"
            :options="selectedDataTypesValues.topEngagement"
            @selectedValue="setToggleValue"
            :externalToggle="selectedDataTypes.topEngagement"
            v-tooltip="{
              content:
                'Vis alle partisider (politikere og organisasjonene), eller kun partienes sider.',
              placement: 'top',
              trigger: 'hover click',
            }"
            id="top"
          />
          <div class="flex absolute top-0 right-0 mr-2 mt-1 md:-mt-1 md:mr-3">
            <img
              :src="logo"
              alt="Laget av Analyse &amp; Tall"
              title="Laget av Analyse &amp; Tall"
              class="h-6 sm:h-8 md:h-10 w-auto"
            />
          </div>
        </div>
        <div class="flex flex-col w-full p-2 md:p-3">
          <div
            v-if="loadingStates.topInteractions"
            class="flex justify-center items-center content-center h-96"
          >
            Laster...
          </div>
          <accordion
            v-if="!loadingStates.topInteractions"
            :content="accordionData"
            id="top-posts"
            :useBorder="false"
            :monotone="true"
          ></accordion>
        </div>
      </div>
    </div>

    <div class="flex w-full pt-3">
      <p class="font-light text-xs text-darkblack text-left">
        <span class="font-semibold">Datagrunnlag:</span>
        {{ selectedScopeDescription }} Kilde: Analyse &amp; Tall 2021.
      </p>
    </div>
  </div>
</template>

<script>
// * Store
import { mapActions, mapGetters, mapMutations } from "vuex";

// * Components
import Accordion from "./Accordion.vue";
import ToggleSwitchMenu from "./ToggleSwitchMenu";
import SetWeek from "@/components/SetWeek.vue";
import OtSelect from "@/otComponents/OtSelect/OtSelect";

// * Icons
// import IconInfo from "@/assets/icons/info.svg";

export default {
  name: "WeekTopEngagement",
  components: { Accordion, ToggleSwitchMenu, SetWeek, OtSelect },
  props: {
    logo: {},
  },
  data: () => ({
    loaded: false,
    data: null,
    accordionData: [],
    //TODO: Move this to store
    allColors: {
      R: "#990014",
      SV: "#d94abf",
      A: "#e51c30",
      SP: "#a5cd39",
      MDG: "#3d8704",
      KrF: "#f0b618",
      V: "#24b38c",
      H: "#00b9f2",
      FrP: "#005799",
      Sentrum: "purple",
    },
    topPostsNr: 10,
    toggleValues: ["Kun partilag", "Alle partisider"],
    // selectedDataType: "only_org_posts",
    selectedData: null,
    key: "",
    allTopInteractionsData: {}, // Holds all our weeks with data
    selectedWeekTopInteractionData: null,
  }),
  computed: {
    ...mapGetters("data", {
      loadingStates: "getLoading",
      selectedWeek: "getSelectedWeek",
      selectedDataTypes: "getSelectedDataTypes",
      selectedDataTypesValues: "getSelectedDataTypesValues",
    }),
    selectedScopeSubTitle() {
      if (this.selectedDataTypes.topEngagement === "all_posts") {
        return "alle partisider";
      } else {
        return "kun partienes sider";
      }
    },
    selectedScopeDescription() {
      if (this.selectedDataTypes.topEngagement === "all_posts") {
        return `Facebook-innlegg fra politiske partier og politikere i uke ${this.selectedWeek}. Engasjement målt i antall likes, emoji-reaksjoner, kommentarer og delinger til hvert innlegg.`;
      } else {
        return `Facebook-innlegg fra partiorganisasjonenes sider i uke ${this.selectedWeek}. Engasjement målt i antall likes, emoji-reaksjoner, kommentarer og delinger til hvert innlegg.`;
      }
    },
  },
  methods: {
    ...mapActions("data", {
      getTopInteractions: "getTopInteractions",
    }),
    ...mapMutations("data", {
      setSelectedDataType: "SET_SELECTED_DATA_TYPE",
    }),
    async loadTopInteractions() {
      await this.getTopInteractions().then((response) => {
        this.selectedWeekTopInteractionData = response;
        this.buildData();
      });
    },
    updatePostNr(event) {
      this.topPostsNr = event;
    },
    setToggleValue(value) {
      if (value === this.toggleValues[1]) {
        this.selectedDataTypes.topEngagement = "all_posts";
      } else if (value === this.toggleValues[0]) {
        this.selectedDataTypes.topEngagement = "only_org_posts";
      }
    },
    buildData() {
      this.accordionData = [];
      let self = this;

      if (this.selectedWeekTopInteractionData !== null) {
        this.selectedWeekTopInteractionData[
          self.selectedDataTypes.topEngagement
        ]
          .slice(0, this.topPostsNr)
          .forEach(function(part, index, arr) {
            self.accordionData.push({
              id: index,
              posted: arr[index]["date"],
              active: index === 0 ? false : false,
              page_name: arr[index]["page_name"],
              title:
                arr[index]["page_level"] === "Nasjonalt parti" ||
                arr[index]["page_level"] === "Regional" ||
                arr[index]["page_level"] === "Ungdomsparti"
                  ? arr[index]["page_name"]
                  : arr[index]["page_name"] +
                    " (" +
                    arr[index]["party_abbr"] +
                    ")",
              content: arr[index]["total_interactions"],
              total_interactions: arr[index]["total_interactions"],
              total_interactions_label:
                index === 0
                  ? parseInt(
                      arr[index]["total_interactions"]
                    ).toLocaleString() + " interaksjoner"
                  : parseInt(arr[index]["total_interactions"]).toLocaleString(),
              rel_total_interactions: arr[index]["rel_total_interactions"],
              color: self.allColors[arr[index]["party_abbr"]],
              message: arr[index]["message"],
              url: arr[index]["fb_post_id"],
              party: arr[index]["party_abbr"],
            });
          });
      } else {
        // TODO: Add a message within the graph if we don't get any data
      }
    },
  },
  async mounted() {
    if (this.selectedWeek !== null && this.selectedWeek !== "undefined") {
      this.loadTopInteractions();
    }
  },
  watch: {
    "selectedDataTypes.topEngagement": function() {
      this.buildData();
    },
    topPostsNr() {
      this.buildData();
    },
    selectedWeek() {
      this.loadTopInteractions();
    },
  },
};
</script>
