<template>
  <div class="flex flex-col">
    <div class="flex flex-col w-full">
      <div
        class="flex relative w-full flex-row content-center justify-center pb-1 md:pb-4"
      >
        <div class="flex flex-col justify-center items-center text-center">
          <div
            class="relative font-bold text-base md:text-xl items-center px-6 pb-3 md:p-0"
          >
            Engasjement skapt av
            {{
              selectedDataTypes.averageEngagement === "all_pages"
                ? "partiene"
                : "partilederne"
            }}
            på Facebook i
            <span class="relative">
              <SetWeek
                :inline="true"
                :color="'normal'"
                class="ml-1 mt-2 md:mt-0 inline-flex md:relative"
              />
            </span>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full bg-gray-100 text-left">
        <div
          class="flex relative w-full justify-center px-2 md:p-0 mb-3 md:mb-0 mt-3"
        >
          <ToggleSwitchMenu
            :values="toggleValues"
            :options="selectedDataTypesValues.averageEngagement"
            @selectedValue="setToggleValue"
            :externalToggle="selectedDataTypes.averageEngagement"
            v-tooltip="{
              content:
                'Filtrer data basert på alle partisider, eller kun sidene til hver partileder.',
              placement: 'top',
              trigger: 'hover click',
            }"
            id="avg"
          />
          <div class="flex absolute top-0 right-0 mr-2 mt-1 md:mt-0 md:mr-3">
            <img
              :src="logo"
              alt="Laget av Analyse &amp; Tall"
              title="Laget av Analyse &amp; Tall"
              class="h-6 sm:h-8 md:h-10 w-auto"
            />
          </div>
        </div>

        <div class="relative flex flex-col w-full p-2 md:p-3">
          <div
            v-if="loadingStates.relInteractions"
            class="flex justify-center items-center content-center h-64"
          >
            Laster...
          </div>
          <div
            v-if="!loadingStates.relInteractions"
            class="flex flex-col w-full"
            v-observe-visibility="{
              callback: visibilityChanged,
              once: true,
            }"
          >
            <bar
              v-for="(item, id) in selectedData"
              :item="item"
              :groupId="selectedDataTypes.averageEngagement"
              :key="id"
              :id="id"
              :barSize="calculateBarSize(parseInt(item[barValueElement]))"
              :partyNames="partyNames"
              :selectedDataType="selectedDataTypes.averageEngagement"
              :barValueElement="barValueElement"
              :isVisible="isVisible"
            >
            </bar>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full pt-3">
      <p class="font-light text-xs text-darkblack text-left">
        <span class="font-semibold">Datagrunnlag:</span>
        {{ selectedScopeDescription }} Kilde: Analyse &amp; Tall 2021.
      </p>
    </div>
  </div>
</template>

<script>
// * Store
import { mapActions, mapGetters, mapMutations } from "vuex";

// * Components
import Bar from "./Bar";
import ToggleSwitchMenu from "./ToggleSwitchMenu";
import SetWeek from "@/components/SetWeek.vue";

// * Data
import partyNames from "@/assets/parties/parties.json";

// * Icons
// import IconInfo from "@/assets/icons/info.svg";

export default {
  name: "WeekAverageEngagement",
  components: { Bar, SetWeek, ToggleSwitchMenu },
  props: {
    // week_nr: {
    //   type: Number,
    //   default: 0,
    // },
    logo: {},
    // loadingStates: {
    //   type: Object,
    // },
    // externalToggle: {},
  },
  data: () => ({
    loaded: false,
    data: null,
    toggleValues: ["Alle partisider", "Kun partiledere"],
    // selectedDataType: "all_pages", //'only_party_leaders'
    selectedData: null,
    minBarSize: 0, // In percentages
    maxBarSize: 100, // In percentages
    minVal: 0,
    maxVal: 0,
    barValueElement: "total_interactions_avg", // top_interactions_rel
    partyNames,
    allRelInteractionsData: {}, // Holds all our weeks with data
    selectedWeekRelInteractionData: null,
    isVisible: false,
    minimumPostsFromPage: 3, // A page needs at least 3 posts to get an average
  }),
  computed: {
    ...mapGetters("data", {
      loadingStates: "getLoading",
      selectedWeek: "getSelectedWeek",
      selectedDataTypes: "getSelectedDataTypes",
      selectedDataTypesValues: "getSelectedDataTypesValues",
    }),
    selectedScopeSubTitle() {
      if (this.selectedDataTypes.averageEngagement === "all_pages") {
        return "alle partisider";
      } else {
        return "partiledernes sider";
      }
    },
    selectedScopeDescription() {
      if (this.selectedDataTypes.averageEngagement === "all_pages") {
        return `Facebook-innlegg fra sider med partitilhørighet i uke ${this.selectedWeek}. Engasjement er målt som gjennomsnitt av alle likes, emoji-reaksjoner, kommentarer og delinger til alle innlegg.`;
      } else {
        return `Facebook-innlegg fra partiledere for partiene på Stortinget i uke ${this.selectedWeek}. Sider med færre enn tre innlegg er holdt utenfor. Engasjement er målt som gjennomsnitt av alle likes, emoji-reaksjoner, kommentarer og delinger til partiledernes innlegg.`;
      }
    },
  },
  methods: {
    ...mapActions("data", {
      getRelInteractions: "getRelInteractions",
    }),
    ...mapMutations("data", {
      setSelectedDataType: "SET_SELECTED_DATA_TYPE",
    }),
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
    },
    async loadRelInteractions() {
      await this.getRelInteractions().then((response) => {
        this.selectedWeekRelInteractionData = response;
        this.buildData();
      });
    },
    setToggleValue(value) {
      if (value === this.toggleValues[0]) {
        this.selectedDataTypes.averageEngagement = "all_pages";
      } else if (value === this.toggleValues[1]) {
        this.selectedDataTypes.averageEngagement = "only_party_leaders";
      }
    },
    // setToggleValue(value) {
    //   if (value === this.selectedDataTypesValues.averageEngagement[0]) {
    //     // this.selectedDataTypes.averageEngagement = "all_pages";
    //     this.setSelectedDataType({
    //       type: "averageEngagement",
    //       value: this.selectedDataTypesValues.averageEngagement[1],
    //     });
    //   } else if (value === this.selectedDataTypesValues.averageEngagement[1]) {
    //     this.setSelectedDataType({
    //       type: "averageEngagement",
    //       value: this.selectedDataTypesValues.averageEngagement[0],
    //     });
    //   }
    // },
    calculateBarSize(val) {
      if (val === 0) {
        return 0;
      } else {
        let res =
          this.minBarSize +
          ((val - this.minVal) * (this.maxBarSize - this.minBarSize)) /
            (this.maxVal - this.minVal);
        return res;
      }
    },
    // getMinValue(data, element) {
    //   // Currently not using this, as we want to filter out 0 values
    //   return data.reduce(
    //     (min, p) => (p[element] < min ? p[element] : min),
    //     data[0][element]
    //   );
    // },
    getMaxValue(data, element) {
      return data.reduce(
        (max, p) => (p[element] > max ? p[element] : max),
        data[0][element]
      );
    },
    buildData() {
      let self = this;
      this.selectedData = this.selectedWeekRelInteractionData[
        this.selectedDataTypes.averageEngagement
      ];

      if (this.selectedDataTypes.averageEngagement === "only_party_leaders") {
        this.selectedData.forEach((el) => {
          if (el.posts < this.minimumPostsFromPage)
            el[self.barValueElement] = 0;
          return el;
        });
      }
      this.calculateBarMinMax();
    },
    calculateBarMinMax() {
      let element = this.barValueElement;
      // let removedZero = this.selectedData.filter((item) => item[element] !== 0);
      // console.log(removedZero);
      //let minVal = this.getMinValue(this.selectedData, element);
      this.minVal = Math.min.apply(
        null,
        this.selectedData.map((v) => v[element]).filter(Boolean)
      );
      this.maxVal = this.getMaxValue(this.selectedData, element);

      this.minBarSize = (this.minVal / this.maxVal) * 100;
      this.maxBarSize = 100;
    },
  },
  async mounted() {
    if (this.selectedWeek !== null && this.selectedWeek !== "undefined") {
      this.loadRelInteractions();
    }
  },
  watch: {
    selectedWeek() {
      this.loadRelInteractions();
    },
    "selectedDataTypes.averageEngagement": function() {
      this.buildData();
    },
  },
};
</script>
