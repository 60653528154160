var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.item.active ? 'is-active' : null],attrs:{"id":_vm.groupId + '-' + _vm.id}},[_c('div',{staticClass:"accordion-item-title"},[_c('div',{staticClass:"flex w-full flex-row text-left items-center bg-transparent border-transparent accordion-item-trigger pb-1"},[_c('div',{staticClass:"flex flex-grow",on:{"mouseover":function($event){_vm.active = true},"mouseleave":function($event){_vm.active = false},"click":function($event){_vm.active = !_vm.active}}},[_c('div',{staticClass:"flex w-2 mr-1",style:(("background-color:" + (_vm.allColors[_vm.item.party_abbr])))}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: ((_vm.item.posts) + " innlegg med totalt " + (parseInt(
              _vm.item.total_interactions_sum
            ).toLocaleString()) + " interaksjoner."),
            placement: 'top-start',
            trigger: 'manual',
            show: _vm.active,
          }),expression:"{\n            content: `${item.posts} innlegg med totalt ${parseInt(\n              item.total_interactions_sum\n            ).toLocaleString()} interaksjoner.`,\n            placement: 'top-start',\n            trigger: 'manual',\n            show: active,\n          }"}],staticClass:"flex justify-between text-xs px-2 py-1 rounded-r-md overflow-hidden shadow-sm transition-all duration-700 text-white",class:[
            _vm.item.posts >= 3 ? 'bg-gray-400' : 'bg-gray-300',
            _vm.item.posts >= 3 && _vm.active
              ? 'bg-gray-500 shadow-lg text-gray-200'
              : null ],style:(_vm.barStyle)},[_c('span',{staticClass:"flex accordion-item-title-text font-semibold truncate overflow-hidden overflow-ellipsis"},[_vm._v(_vm._s(_vm.showItemTitle))]),(_vm.barSize > _vm.barSizeCutoff)?_c('span',{staticClass:"text-white text-left"},[_vm._v(_vm._s(parseInt(_vm.item[_vm.barValueElement]).toLocaleString()))]):_vm._e()]),(_vm.barSize <= _vm.barSizeCutoff)?_c('span',{staticClass:"pl-1 py-1 text-right truncate overflow-clip",class:[
            parseInt(_vm.item[_vm.barValueElement]) === 0
              ? 'text-gray-300'
              : 'text-gray-500' ]},[_vm._v(_vm._s(_vm.showItemDetails))]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }