export function mx_sort_by(field, reverse, primer) {
  // https://stackoverflow.com/questions/979256/sorting-an-array-of-objects-by-property-values
  const key = primer
    ? function(x) {
        return primer(x[field]);
      }
    : function(x) {
        return x[field];
      };
  reverse = !reverse ? 1 : -1;
  return function(a, b) {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
  };
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function sumArray(items, prop) {
  return items.reduce(function(a, b) {
    return a + b[prop];
  }, 0);
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Normalizes a value from one range (current) to another (new).
 *
 * @param  { Number } val    //the current value (part of the current range).
 * @param  { Number } minVal //the min value of the current value range.
 * @param  { Number } maxVal //the max value of the current value range.
 * @param  { Number } newMin //the min value of the new value range.
 * @param  { Number } newMax //the max value of the new value range.
 *
 * @returns { Number } the normalized value.
 */
export function normalizeBetweenTwoRanges(val, minVal, maxVal, newMin, newMax) {
  return newMin + ((val - minVal) * (newMax - newMin)) / (maxVal - minVal);
}

export function ConvertMinutes(num) {
  // Convert hours to more readable format

  let d = Math.floor(num / 24);
  var r = num % 24;
  var h = Math.floor(r);
  var m = Math.floor(60 * (r - h));

  if (d > 0) {
    return d + " dager, " + h + " timer og " + m + " minutter";
  } else {
    return h + " timer og " + m + " minutter";
  }
}
